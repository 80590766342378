import '../../css/jobApply.css';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useContext, useState } from 'react';
import { LanguageContext } from '../../state/languageContext';
import { useEffect } from 'react';

const JobApply = () => {
  // Language object
  const { language } = useContext(LanguageContext);

  function strrev(str) {
    let dateArr = str.split('-');
    let n = dateArr.length;
    let newArr = [];
    for (let i = n - 1; i >= 0; i--) {
      newArr.push(dateArr[i]);
    }

    return newArr.join('/'); //separatorul final
  }

  const [isRecaptchaValid, setRecaptchaValid] = useState(false);
  const [cv, setCV] = useState({ preview: '', data: '' });

  const handleFileChange = e => {
    const file = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setCV(file);
  };

  function isPhoneValid(number) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (number.match(regex)) return true;

    return false;
  }

  function isNameValid(name) {
    if (name.length >= 2) return true;

    return false;
  }

  function isEmailValid(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(regex)) return true;

    return false;
  }

  function validateForm(e) {
    e.preventDefault();
    const form = document.forms['apply-form'];

    const name = form['name'].value;
    const email = form['email'].value;
    const phone = form['phone'].value;
    const message = form['message'].value;

    let errors = false;
    if (!name) {
      document.querySelector('.error-valid-name').style.display = 'none';
      document.querySelector('.error-name').style.display = 'block';
      errors = true;
    } else if (!isNameValid(name)) {
      document.querySelector('.error-name').style.display = 'none';
      document.querySelector('.error-valid-name').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('.error-name').style.display = 'none';
      document.querySelector('.error-valid-name').style.display = 'none';
    }

    if (!email) {
      document.querySelector('.error-valid-email').style.display = 'none';
      document.querySelector('.error-email').style.display = 'block';
      errors = true;
    } else if (!isEmailValid(email)) {
      document.querySelector('.error-email').style.display = 'none';
      document.querySelector('.error-valid-email').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('.error-email').style.display = 'none';
      document.querySelector('.error-valid-email').style.display = 'none';
    }

    if (!phone) {
      document.querySelector('.error-valid-phone').style.display = 'none';
      document.querySelector('.error-phone').style.display = 'block';
      errors = true;
    } else if (!isPhoneValid(phone)) {
      document.querySelector('.error-phone').style.display = 'none';
      document.querySelector('.error-valid-phone').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('.error-phone').style.display = 'none';
      document.querySelector('.error-valid-phone').style.display = 'none';
    }

    if (!message) {
      document.querySelector('.error-message').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('.error-message').style.display = 'none';
    }

    if (!isRecaptchaValid) {
      document.querySelector('.error-recaptcha').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('.error-recaptcha').style.display = 'none';
    }

    if (name && email && phone && message && isRecaptchaValid) {
      const thanks = document.getElementById('thanks');
      thanks.style.display = 'block';

      let clean = document.querySelectorAll('input');
      clean.forEach(input => {
        input.value = '';
      });
    } else {
      const thanks = document.getElementById('thanks');
      thanks.style.display = 'none';
    }

    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('message', message);
    formData.append('cv', cv.data);

    const dateObject = new Date();
    const dateReversed = dateObject.toISOString().split('T')[0];
    const date = strrev(dateReversed);
    const time = `${dateObject.getHours()}:${dateObject.getMinutes()}`;

    const job = 'App-developer';

    formData.append('date', date);
    formData.append('time', time);
    formData.append('job', job);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/apply-job`,
      data: formData,
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
    if (!errors) return true;
    return false;
  }

  useEffect(() => {
    document
      .querySelector('.section-job-apply')
      .querySelectorAll('.styled-input')
      .forEach(container => {
        let input = container.querySelector('input');
        input.addEventListener('change', e => {
          if (e.target.value) {
            container.querySelector('label').style.fontSize = '1.25rem';
            container.querySelector('label').style.top = '-2.6rem';
            container.querySelector('label').style.transition = 'all 0.125s ease';
          } else {
            container.querySelector('label').style.fontSize = 'inherit';
            container.querySelector('label').style.top = 'inherit';
          }
        });
      });
  });

  return (
    <div id="job-apply-page">
      <div className="formApply">
        <p className="textApply">{language.job_apply.title}</p>
      </div>

      <section className="section-job-apply">
        <form onSubmit={validateForm} encType="multipart/form-data" id="apply-form" name="apply-form">
          <div className="styled-input wide1 jobs-form" style={{ minWidth: '100%' }}>
            <input type="text" className="inputApply" name="name" autoComplete="on" />
            <label>{language.job_apply.form_apply.name}</label>
            <div className="error error-name"> {language.job_apply.form_apply.error_name} </div>
            <div className="error error-valid-name"> {language.job_apply.form_apply.error_valid_name} </div>
          </div>

          <div className="styled-input wide1 jobs-form" style={{ minWidth: '100%' }}>
            <input name="email" className="inputApply" type="email" autoComplete="on" />
            <label>{language.job_apply.form_apply.email}</label>
            <div className="error error-email">{language.job_apply.form_apply.error_email}</div>
            <div className="error error-valid-email">{language.job_apply.form_apply.error_valid_email}</div>
          </div>

          <div className="styled-input jobs-form" style={{ minWidth: '100%' }}>
            <input type="tel" className="inputApply" name="phone" pattern="[0-9]{10}" autoComplete="on" />
            <label>{language.job_apply.form_apply.phone}</label>
            <div className="error error-phone">{language.job_apply.form_apply.error_phone}</div>
            <div className="error error-valid-phone">{language.job_apply.form_apply.error_valid_phone}</div>
          </div>

          <div className="styled-input wide jobs-form">
            {/* <textarea className="inputApply" name="message" ></textarea> */}
            <input className="inputApply message-area" name="message" />
            <label>{language.job_apply.form_apply.message}</label>
            <div className="error error-message">{language.job_apply.form_apply.error_message}</div>
          </div>

          <div className="file-form">
            <div>{language.job_apply.form_apply.files_allowed}</div>
            <input type="file" name="cv" id="cv" className="uploadBg" onChange={handleFileChange} accept="image/*, .pdf" />
          </div>

          <ReCAPTCHA sitekey="6Le4A2MeAAAAANKeUSpk1m4Y5aPUbtwIpjWF02DZ" onChange={() => setRecaptchaValid(true)} />
          <div className="error error-recaptcha">{language.job_apply.form_apply.error_recaptcha}</div>

          <div id="thanks"> Multumim de aplicare, solicitarea a fost luata in vedere!</div>
          <br />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="button-careers">
            <button type="submit" name="submit" value="submit" className="btn-apply button-text" style={{ cursor: 'pointer', border: 'none', color: 'white', backgroundColor: 'black' }}>
              {language.job_apply.form_apply.submit_btn}
            </button>
            <img src="../imagini/baseline_arrow_right_alt_white_24dp.png" alt="test" className="arrow2" />
          </div>
        </form>
      </section>
    </div>
  );
};

export default JobApply;

import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";
const Hero = () => {
  // Language object
  const {language} = useContext(LanguageContext);

  return (
    <div id="blog-page-description" className="container">
      <div className="row first-line">
        <div className="col-lg-7">
          <h1 id="title">{language.blog.hero.title}</h1>
          <p id="description">{language.blog.hero.description}</p>
        </div>

        <div className="col-lg-5">
          <img className="laptop" src="imagini/laptop.png" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

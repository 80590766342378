import TypeIt from "typeit-react";
import { LightModeContext } from "../../state/lightModeContext";
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  const {language} = useContext(LanguageContext);
  
  // Light Mode context
  const {lightMode} = useContext(LightModeContext);

  const typeit_options = {
    strings: [`${language.homepage.hero.description.typeit.string1}`, `${language.homepage.hero.description.typeit.string2}`],
    speed: 200,
    waitUntilVisible: true,
    startDelay: 500,
    deleteSpeed: 100,
    nextStringDelay: 2000,
    breakLines: false,
    lifeLike: true,
    waitUntilVisible: true,
    loop: true,
    loopDelay: 1500
  };

  return (
    <section id="hero">
      <div id="showcase">
        <div id="shc1">
          <h1>
            {language.homepage.hero.description.line1}<br/>
            <TypeIt className="has-text-link" options={typeit_options} /><br />
            {language.homepage.hero.description.line3} <br />
          </h1>
        </div>

        <div>
          <h1 id="shc2">
            Dezvoltam aplicatii mobile <br className="br-taken-mobile" /> <br/><span className="has-text-link"></span><br />
            si web!<br />
          </h1>
        </div>
      </div>

      <div className="get-in-touch hover-efect" style={{justifyContent: "flex-start"}} >
        <h3 id="get-in-touch-title">{language.homepage.hero.contact_us.line1} </h3>
        <div className="contact">
          <Link to="/contact"><p id="textFloatLeft">{language.homepage.hero.contact_us.line2}</p></Link>
          <Link to="/contact">
            <img src={`imagini/${!lightMode ? 'baseline_arrow_right_alt_white_24dp' : 'baseline_arrow_right_alt_black_18dp'}.png`}/>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
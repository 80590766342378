import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow } from 'swiper';

const Stacks = () => {
  const technologies = [
    'flutter',
    'expressjs',
    'firebase',
    'javascript',
    'kotlin',
    'mongodb',
    'mysql',
    'nodejs',
    'php',
    'react',
    'supabase',
    'swift',
    'vue',
    'wordpress'
  ]

  const coverflowEffectOptions = {
    rotate: 30,
    stretch: 0,
    depth: 200,
    modifier: 1,
    slideShadows: true,
  }

  return (
    <Swiper
      modules={[Autoplay, EffectCoverflow]}
      autoplay
      effect='coverflow'
      coverflowEffect={coverflowEffectOptions}
      loop={true}
      spaceBetween={20}
      grabCursor={true}
      noSwiping={true}
      slidesPerView={`auto`}
      centeredSlides={true}
    >
      {
        technologies.map((tech, index) => {
          return (
            <SwiperSlide key={index} className="app-swiper">
              <img src={`imagini/Logos/${tech}.svg`} />
            </SwiperSlide>
          )
        })
      }
    </Swiper>
  );
};

export default Stacks;

import '../css/termsAndConditions.css'

const TermsAndConditions = () => {
    window.scrollTo(0, 0);
  return (
    <div id="terms-and-conditions">
      <div className="Cookie"> 
        <h1 className="cookie_header">Termeni si Conditii</h1>
        <p className="cookie_p"><strong>Dispozitii generale</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Acesti termeni si conditii (denumite in continuare "Termeni") se aplica tuturor relatiilor juridice viitoare dintre 
                    Wolf Digital Media, Strada Scoala Floreasca nr.31, Bucuresti,  <a href="mailto:contact@wolfdigitalmedia.ro">contact@wolfdigitalmedia.ro</a>,
                    <a href="tel:0040726585045">0040.726.585.045</a> CUI 37760870 ca antreprenor (denumita in continuare "Agentie")
                    si clientul (denumit in continuare "Client").</li>
                <li className="li-tc-privacy"> Afacerile vor fi incheiate numai pe baza acestor termeni si conditii. Noi in mod explicit nu acceptam 
                    Termenii si conditiile Clientului, cu exceptia cazului in care s-a convenit altfel in scris intr-un anumit caz. Derogari sau clauze 
                    in conflict cu sau suplimentare la acesti Termeni care fac parte din termenii si conditiile altor parti nu se vor forma 
                    parte a contractului chiar suntem constienti de astfel de clauze. Agentia nu este obligata sa se opuna 
                    termenii si conditiile sub orice alta forma.</li>
                <li className="li-tc-privacy">Agentia isi ofera serviciile fara caracter obligatoriu. Numai comenzile sau 
                    cererile de modificare formulate in scris sunt obligatorii.</li>
                <li className="li-tc-privacy">In cazul oricarui conflict intre o oferta, documente generale sau specifice 
                    daca este cazul, Contractul Agentiei sau acesti Termeni, documentele se vor aplica in ordinea de mai sus, 
                    deoarece elementele mai individuale vor modifica elementele generale.</li>
                <li className="li-tc-privacy">Modificarile Termenilor vor fi comunicate si vor fi convenite de catre Client in cazul in care Clientul nu
                    la o modificare a Termenilor in termen de 14 zile in scris; orice astfel de notificare va informa in mod expres Clientul cu privire la
                    consecintele tacerii sale si clauzele specifice care au facut obiectul modificarii; acest lucru nu se aplica modificarilor
                    in continutul si comisioanele serviciilor materiale.</li>
          </ol>
       </p> <br />
           <p className="cookie_p"><strong>Protectia conceptelor si ideilor</strong><br /><br />
            In cazul in care Clientul a invitat deja Agentia sa elaboreze un concept, iar agentia a preluat invitatia 
            la incheierea contractului principal, se vor aplica urmatoarele:
            <br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Clientul si Agentia vor incheia deja o relatie contractuala odata ce Clientul invita Agentia si 
                    Agentia preia invitatia Clientului de a dezvolta un concept ("Contract de pitching"). De asemenea, un astfel de contract este
                     guvernate de acesti Termeni</li>
                <li className="li-tc-privacy">Clientul recunoaste ca Agentia va oferi deja contributii costisitoare in contextul crearii unui concept, desi 
                    clientul in sine nu a acceptat inca nicio obligatie de plata.</li>
                <li className="li-tc-privacy">Partile lingvistice si grafice ale conceptului sunt protejate prin drepturi de autor, cu conditia sa atinga un nivel de originalitate conform 
                    prevederile Legii  privind drepturile de autor, Clientul nu poate utiliza si prelucra aceste parti fara consimtamantul Agentiei.</li>
                <li className="li-tc-privacy">In plus, conceptul contine idei relevante pentru publicitate care nu ating un nivel de originalitate si, prin urmare,
                    nu sunt protejate prin drepturi de autor. Aceste idei vin in faza incipienta a fiecarui proces creativ si pot fi definite ca catalizator pentru 
                    ulterioare si, prin urmare, ca origine a unei strategii de marketing. Elementele originale si care caracterizeaza
                    prin urmare, sunt protejate. In sensul acestui acord, ideile includ, dar nu se limiteaza la sloganuri publicitare, 
                    texte publicitare, grafica si ilustratii, materiale publicitare si alte materiale similare, chiar daca aceste materiale nu ating un nivel 
                    de originalitate.</li>
                <li className="li-tc-privacy">Clientul se angajeaza sa nu exploateze economic sau sa utilizeze sau sa permita exploatarea economica sau utilizarea acestor idei de 
                    publicitate creativa pe care agentia le prezinta in contextul conceptului in afara domeniului de aplicare al unui contract principal care va fi incheiat ulterior.</li>
                <li className="li-tc-privacy">In cazul in care Clientul considera ca Agentia a prezentat idei la care clientul s-a gandit deja inainte de prezentare, Clientul va informa 
                    Agentia prin e-mail in termen de 14 zile de la data prezentarii si va furniza dovezi documentare care permit Agentiei sa atribuie aceeasi cronologic.</li>
                <li className="li-tc-privacy">In cazul in care acest lucru nu este cazul, partile considera ca agentia a prezentat Clientului o noua idee. Daca Clientul foloseste 
                    ideea, se presupune ca agentia a furnizat servicii meritorii.</li>
                <li className="li-tc-privacy">Clientul poate solicita o scutire de la obligatiile sale in temeiul prezentului alineat, achitand o compensatie rezonabila, plus o taxa pe 
                    valoarea adaugata legala. Taxele mentionate in estimarea costurilor sunt considerate o compensatie rezonabila. Clientul este scutit de obligatia sa, pana cand 
                    agentia nu a primit compensatia completa. </li>
              </ol>
            </p> <br />
            <p className="cookie_p"><strong>Domeniul de aplicare a serviciilor</strong><br /><br />
                <ol className="tcPadding">
                <li className="li-tc-privacy">Domeniul obligatoriu al serviciilor poate fi derivat din confirmarea de catre agentie a comenzii Clientului sau din alte specifictii
                    cuprinse intr-un contract scris. Sfera serviciilor poate fi modificata ulterior numai cu confirmarea scrisa a agentiei.
                    In contextul executarii comenzii, agentia are libertate creativa in sfera de aplicare definita de Client. </li>
                <li className="li-tc-privacy">Clientul va revizui si elibera intr-un termen rezonabil dupa primirea tuturor serviciilor (inclusiv, dar fara a se limita la schitele (preliminare),
                    machetele, grafica, proiecte, sabloane, videoclipuri, fotografii, planuri editoriale si fisiere electronice). Agentia nu poate garanta respectarea programului convenit
                    daca Clientul nu elibereaza aceste materiale la timp. Agentia isi declina orice raspundere pentru daunele cauzate de aceasta intarziere.</li>
              </ol>
           </p> <br />
           <p className="cookie_p"><strong>Obligatiile clientului de a coopera</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Clientul va pune la dispozitia agentiei in timp util si complet orice informatii si documente necesare pentru furnizarea serviciului. 
                    Clientul va anunta Agentia cu privire la orice circumstante care sunt semnificative pentru executarea contractului, chiar daca Clientul cunoaste acest lucru 
                    numai in timpul executarii contractului. Orice cheltuieli pe care agentia le-a facut in legatura cu necesitatea repetarii lucrarilor din cauza informatiilor inexacte,
                    incomplete sau modificate ulterior sau din orice intarzieri ale Clientului vor fi suportate de catre Client.</li>
                <li className="li-tc-privacy">Clientul se angajeaza sa examineze daca documentele (cum ar fi imagini, fotografii, logo-uri etc.) pe care Clientul le furnizeaza agentiei 
                    pentru executarea contractului sunt supuse drepturilor de autor, drepturilor de marca, drepturilor asupra semnelor sau altor drepturi ale tertilor si garanteaza 
                    ca documentele nu au drepturi terte si pot fi utilizate pentru contract. Clientul va detine inofensiv si va indemniza Agentia in acest sens.<br /><br />
                    Clientul inlocuieste orice dezavantaje pe care agentia le sufera din cauza acestor creante ale unor terti, inclusiv, dar fara a se limita la taxele legale 
                    ale agentiei si ale adversarului.<br /><br />
                    Clientul se angajeaza sa sprijine Agentia in legatura cu apararea unor astfel de pretentii terte. Clientul va pune la dispozitia agentiei documentele si informatiile necesare.</li>
                <li className="li-tc-privacy">Clientul garanteaza si se asigura ca a obtinut acordurile necesare ale tertilor relevanti pentru prelucrarea, stocarea si transferul datelor 
                    cu caracter personal ale tertilor.</li>
                </ol>
           </p> <br />
           <p className="cookie_p"><strong>Servicii terte</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Agentia poate, la propria discretie, sa presteze serviciile in sine, sa utilizeze terti calificati ca agenti pentru furnizarea serviciilor
                    contractuale si / sau sa subcontracteze astfel de servicii („servicii terte”).</li>
                <li className="li-tc-privacy">Agentia va numi terti pentru furnizarea de servicii terte, fie in nume propriu, fie cu notificare prealabila, in numele Clientului. 
                    Agentia va selecta acest tert cu atentie si se va asigura ca tertul detine calificarile profesionale necesare.</li>
                <li className="li-tc-privacy">Clientul va accepta obligatiile fata de terte care au fost notificate Clientului si care supravietuiesc dupa durata acestui Contract. 
                    Pentru evitarea indoielilor, acest lucru se aplica si in cazul in care Contractul de agentie este reziliat pentru o cauza buna.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Date tinta</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Cu exceptia cazului in care agentia a confirmat explicit ca termenele sunt obligatorii, termenele indicate pentru livrari sau servicii 
                    nu sunt obligatorii. Acordurile de legare cu privire la datele-tinta se incheie in scris si trebuie confirmate de catre agentie in scris.</li>
                <li className="li-tc-privacy">Agentia nu este considerata ca fiind implicita in cazul in care o intarziere este cauzata de neprezentarea Clientului la sedintele programate 
                    si de a furniza orice lucrare necesara si toate documentele intr-o maniera completa si de a coopera, dupa caz.</li>
                <li className="li-tc-privacy">Daca livrarea / performanta intarzie sa fie din motive care nu sunt controlate de agentie, cum ar fi evenimente de forta majora si 
                    alte evenimente imprevizibile care nu pot fi prevenite prin mijloace rezonabile, obligatiile de serviciu vor fi suspendate pe toata durata si in sfera de 
                    aplicare a impedimentului. Termenele vor fi prelungite in consecinta. Clientul si Agentia se pot retrage din contract daca aceste intarzieri 
                    dureaza mai mult de doua luni.</li>
                <li className="li-tc-privacy">In cazul in care Agentia este in culpa, Clientul nu se poate retrage din contract numai daca Agentia nu indeplineste in termen de cel putin
                    14 zile de gratie acordat in scris. Clientul nu poate pretinde daune din cauza neexecutarii sau neplacerii, cu exceptia cazului in care au fost furnizate dovezi 
                    de intentie sau neglijenta grava.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Incetarea prematura</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Agentia poate rezilia contractul pentru o cauza buna fara o notificare prealabila. Daca se considera ca o cauza buna exista:
                    <ol style={{ listStylePosition: ' lower-alpha' }} id="letters">
                        <li className="li-tc-privacy">executarea serviciului este imposibila sau intarziata in continuare, in ciuda unei perioade de gratie de 14 zile din 
                            motive aflate sub controlul Clientului;</li>
                        <li className="li-tc-privacy">Clientul incalca in mod repetat obligatiile materiale din cadrul prezentului Contract, cum ar fi plata unei sume datorate
                            sau a indatoririlor de cooperare, in ciuda unui memento scris si a unei perioade de gratie de 14 zile;</li>
                        <li className="li-tc-privacy">exista ingrijorari justificate cu privire la situatia de credit a Clientului si Clientul nu ia in considerare cererea agentiei
                            de plata in avans sau nu furnizeaza titluri adecvate;</li>
                        <li className="li-tc-privacy">procedurile de faliment sau compozitie sunt deschise pentru activele Clientului sau o cerere de deschidere a unei astfel de 
                            proceduri este respinsa din lipsa activelor pentru acoperirea costurilor sau in cazul in care Clientul suspenda platile.</li>
                    </ol>
                </li>
                <li className="li-tc-privacy">Clientul poate rezilia contractul pentru o cauza buna fara o notificare prealabila. O cauza buna se considera ca exista in special
                    daca Agentia incalca in mod repetat conditiile materiale ale acestui contract, in ciuda unui memento scris si nu reuseste sa remedieze aceasta incalcare 
                    intr-un termen de 14 zile.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Taxe, Conditii de plata, rezervarea titlului.</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Agentia are dreptul la o taxa pentru fiecare serviciu individual imediat ce a fost prestata. Plata se face fara nicio deducere in
                    termen de 14 (paisprezece) zile de la data primirii facturii. Aceasta se aplica si cheltuielilor cu numerar si a altor cheltuieli percepute Clientului.
                    Marfurile furnizate de agentie ramaan proprietatea agentiei pana cand Clientul a achitat compensatia, in integralitatea sa, inclusiv orice pasiv auxiliar.</li>
                <li className="li-tc-privacy">Agentia poate solicita plati in cont si emite facturi provizorii sau in avans.</li>
                <li className="li-tc-privacy">Taxele agentiei sunt comisioane net plus taxa pe valoarea adaugata legala. Serviciile care nu sunt acoperite in mod explicit de compensatia 
                    convenita vor fi percepute separat. In plus, Clientul ramburseaza toate cheltuielile cu numerar.</li>
                <li className="li-tc-privacy">Estimarile costurilor nu sunt obligatorii si nu constituie o oferta. Acestea nu obliga agentia sa execute serviciile specificate in aceasta.
                    In caz de indoiala, estimarile costurilor sunt gratuite. Chiar daca se atribuie un contract, toate proiectele, planurile, calculele si alte documente raman 
                    proprietatea intelectuala a agentiei si pot fi exploatate sau transferate catre terti numai cu acordul scris al agentiei.</li>
                <li className="li-tc-privacy">Agentia are dreptul la compensatia convenita si pentru lucrari, pe care Clientul nu le-ar putea pune in aplicare din niciun motiv. 
                    Plata compensatiei nu acorda Clientului dreptul de a folosi lucrarile deja executate. Clientul va reveni imediat la Agentie sau va distruge concepte, proiecte 
                    si alte documente neimplementate.</li>
                <li className="li-tc-privacy">In cazul in care platile Clientului intarzie, Agentia va percepe dobanda de neplata legala la o rata aplicabila afacerilor comerciale. 
                    Agentia isi rezerva in mod explicit drepturi si creante suplimentare.</li>
                <li className="li-tc-privacy">Daca oricare dintre platile Clientului intarzie, Agentia poate solicita, de asemenea, plata imediata a tuturor serviciilor si serviciilor 
                    partiale furnizate in contextul altor contracte incheiate cu Clientul. Agentia poate refuza sa furnizeze servicii pana cand Clientul nu a achitat suma restanta. 
                    In cazul in care a fost convenita plata in rate, intreaga compensare este datorata daca Clientul nu a platit sume partiale sau creante auxiliare la timp. 
                    Intreaga suma restanta datorata este datorata imediat pentru plata.</li>
                <li className="li-tc-privacy">Daca platile Clientului intarzie, Agentia poate solicita plata imediata a tuturor serviciilor si serviciilor partiale aparute in contextul 
                    altor contracte cu Clientul.</li>
                <li className="li-tc-privacy">Agentia nu este obligata sa furnizeze servicii suplimentare Clientului pana cand Clientul nu a achitat suma restanta (dreptul de retentie). 
                    Acest lucru nu afecteaza obligatia Clientului de a plati taxa agentiei.</li>
                <li className="li-tc-privacy">Daca Clientul si Agentia au convenit asupra platilor in rate, intreaga cerere este datorata pentru plata, chiar daca Clientul nu plateste in 
                    timp util o singura transa sau o cerere accesorie. In acest caz, agentia poate solicita plata imediata a intregii datorii restante.</li>
                <li className="li-tc-privacy">Clientul nu poate compensa propriile cereri impotriva revendicarilor agentiei, cu exceptia cazului in care revendicarile Clientului au fost
                    recunoscute de catre agentie in scris sau stabilite de o instanta.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Proprietate intelectuala si drepturi de licenta</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Contractele atribuite agentiei constituie servicii protejate de drepturi de autor. Aceasta include, de asemenea, prezentari, 
                    schite, concepte, machete etc. Agentia isi rezerva toate drepturile de autor. Clientului i se acorda doar dreptul neexclusiv si netransmisibil de a 
                    utiliza aceste servicii dupa plata integrala a compensatiei convenite, in scopul convenit, in domeniul de aplicare convenit si pentru timpul convenit.</li>
                <li className="li-tc-privacy">Daca Clientul doreste sa utilizeze serviciile Agentiei pentru un alt scop decat scopul convenit initial, Clientul obtine acordul Agentiei,
                    indiferent daca serviciul sau lucrarea este sau nu protejata de copyright.</li>
                <li className="li-tc-privacy">Agentia are dreptul exclusiv de a acorda permisiuni sau drepturi de utilizare a lucrarilor; acestea pot fi limitate in timp sau limitate 
                    la anumite teritorii. Orice astfel de permis sau drept este acordat printr-un acord separat. Cu exceptia cazului in care s-a ajuns la un acord separat in domeniul 
                    de aplicare al unei permisiuni sau a unui drept, va exista dreptul de a utiliza o lucrare pentru o perioada nedeterminata, totusi limitata pe teritoriul Romaniei.
                    Nu se acorda in mod automat permisiuni sau drepturi pentru produsele care nu fac obiectul serviciului. Agentia isi rezerva singurul drept de a reproduce, 
                    de a schimba, de a pune la dispozitia tertilor sau de a utiliza serviciul sau munca intr-o alta forma decat cea convenita.</li>
                <li className="li-tc-privacy">In mod invariabil, agentia isi rezerva dreptul de prelucrare, cu exceptia cazului in care dreptul de prelucrare este transferat in mod 
                    explicit printr-un acord scris. In cazul in care a fost acordat un drept de prelucrare, numele creatorului nu trebuie sa fie aplicat rezultatului astfel incat
                    lucrarea procesata sa para originala.</li>
                <li className="li-tc-privacy">In cazul in care drepturile de licenta sunt achizitionate pentru lucrarile utilizate, Clientul se va asigura ca lucrarea nu este utilizata 
                    in afara domeniului de activitate, timp si teritoriu pentru care au fost obtinute drepturile de licenta.<br /><br />
                    Daca nu este convenit separat, agentia nu va tine la zi drepturile dobandite (imagini, imagini stoc, domenii, software si alte drepturi de licenta) si nu va avertiza 
                    Clientul inainte de expirare. Clientul se va asigura ca drepturile obtinute pentru un timp limitat sunt reinnoite in timp util.
                </li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Etichetare si referinta</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Agentia are dreptul liber de a denumi autorul sau agentia asupra tuturor materialelor publicitare si in contextul tuturor masurilor 
                    de publicitate. Clientul nu are dreptul la nicio compensatie in schimb.</li>
                <li className="li-tc-privacy">Agentia poate utiliza numele si sigla pentru a se referi la relatia sa de afaceri cu Clientul in propriile sale suporturi de publicitate, 
                    inclusiv, dar fara a se limita la site-ul sau web („marketing de referinta”), fara a se compensa separat clientul.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Garantie</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Clientul va notifica orice defecte imediat, in termen de opt zile dupa livrarea / serviciul agentiei, cel tarziu, specificand si descriind
                    in detaliu defectul. Defectele ascunse trebuie notificate in scris in termen de opt zile de la depistarea acestora, descriind in detaliu defectul. in caz contrar, 
                    serviciul este considerat aprobat; in acest caz, Clientul nu poate depune cereri de garantie sau de compensare a prejudiciului si nu are dreptul sa evite contractul
                    pe motiv de eroare din cauza unui defect.</li>
                <li className="li-tc-privacy">Daca Clientul depune o reclamatie justificata si la timp din cauza unui defect, Clientul are dreptul la rectificarea sau schimbul 
                    livrarii / serviciului. Clientul acorda agentiei un termen rezonabil de cel putin 14 zile pentru a face acest lucru. Daca imbunatatirea performantei este 
                    imposibila sau presupune un efort disproportionat de mare pentru agentie, agentia are dreptul sa refuze imbunatatirea. In acest caz, Clientul poate anula contractul
                    sau poate solicita reducerea pretului de achizitie, cu conditia indeplinirii cerintelor legale. In caz de imbunatatire, Clientul va livra obiectul (fizic) defect la
                    costul si cheltuielile sale.</li>
                <li className="li-tc-privacy">Agentia nu este obligata sa ia masuri corective pentru a remedia un defect daca defectele din controlul Clientului impiedica actiuni corective 
                    si Clientul nu rectifica aceste defecte intr-o perioada rezonabila de timp.</li>
                <li className="li-tc-privacy">Agentia nu garanteaza pentru performanta tertilor, daca un tert a fost comandat in conformitate cu VI.2 din prezenta, in numele si in numele Clientului.</li>
                <li className="li-tc-privacy">Partile sunt de acord ca agentia are libertatea creativa in sfera de aplicare a contractului si ca serviciile agentiei constituie o activitate 
                    creativa. Agentia nu garanteaza ca serviciile furnizate vor corespunde dorintelor si cerintelor Clientului, cu exceptia cazului in care sunt convenite in mod specific 
                    in contract. Agentia pune in aplicare cerintele stabilite in confirmarea comenzii, dupa propria discretie. Clientul nu poate afirma revendicari de garantie daca Clientului 
                    nu ii place rezultatul, desi corespunde cerintelor prevazute in confirmarea comenzii.</li>
                <li className="li-tc-privacy">Este responsabilitatea Clientului sa examineze daca serviciul este admisibil in conformitate cu dispozitiile legale, inclusiv, dar fara a se 
                    limita la dreptul concurentei, dreptul la marci comerciale, dreptul la copyright si dreptul administrativ. Pentru evitarea indoielilor, contractul nu prevede, 
                    iar Agentia nu efectueaza asemenea revizuiri legale, cu exceptia cazului in care Clientul si Agentia sunt de acord separat cu privire la acestea.</li>
                <li className="li-tc-privacy">Perioada de garantie este de sase luni de la livrare / service. Dreptul de a face cerere de recurs in privinta agentiei expira la 12 luni
                    de la livrare / executare.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Raspundere</strong><br /><br />
            <ol className="tcPadding">
                <li className="li-tc-privacy">Agentia nu va fi responsabila pentru daune materiale sau daune financiare suferite de Client in cazuri de neglijenta usoara si grava, 
                    indiferent daca este vorba de daune directe sau indirecte, profit pierdut sau daune consecinte, daune datorate neplatirii, imposibilitate, inalcare pozitiva a
                    obligatiilor, culpa in contextul incheierii contractului, executarea defectuoasa sau incompleta. Clientul este obligat sa dovedeasca neglijenta sau intentie grava.
                    In masura permisa de lege, Clientul nu poate solicita, in niciun caz, compensatii pentru daune si consecinte financiare, pierderi de economii, pierderi de dobanda 
                    si daune rezultate din agentii terti.</li>
                <li className="li-tc-privacy">Clientul este raspunzator pentru daune sau eforturi suplimentare efectuate de agentie din cauza faptului ca datele si documentele
                    furnizate de acesta nu sunt utilizate pentru ca incalca drepturile tertilor sau contin continut ilegal sau sunt improprii pentru furnizarea de serviciile.
                    Clientul va detine inofensiv si va indemniza Agentia in acest sens.</li>
                <li className="li-tc-privacy">Clientul se angajeaza sa examineze daca documente (cum ar fi imagini, fotografii, logo-uri etc.) sunt supuse drepturilor de autor,
                    drepturilor de marca, drepturilor asupra semnelor sau altor drepturi ale tertilor. Agentia nu este responsabila pentru nicio incalcare a acestor drepturi, 
                    iar Clientul va detine inofensiv si va despagubi agentia in acest sens. Clientul este obligat sa inlocuiasca orice dezavantaje care rezulta din creante ale unor terti,
                    inclusiv taxe legale rezonabile. Mai mult, Clientul se angajeaza sa asiste agentia in legatura cu apararea cererilor formulate de terti si sa furnizeze toate documentele
                    justificative necesare.</li>
                <li className="li-tc-privacy">Agentia isi declina in mod explicit orice raspundere pentru creantele declarate fata de Client pe baza serviciilor furnizate de Agentie, 
                    cu conditia ca agentia sa-si fi indeplinit obligatia de a informa Clientul sau o astfel de obligatie nu era evidenta. O neglijenta usoara nu face rau. 
                    Agentia nu este responsabila pentru cheltuielile de contencios, pentru propriile taxe legale ale Clientului sau pentru costurile de publicare a hotararilor
                    judecatoresti sau pentru alte cereri de compensare a daunelor sau a altor pretentii ale tertilor. Clientul va detine inofensiv si va indemniza Agentia in acest sens.</li>
                <li className="li-tc-privacy">Cererile Clientului pentru compensarea daunelor vor disparea la sase luni de la cunoasterea prejudiciului si sunt, in orice caz, restrictionate
                    prin statut, la trei ani de la incalcarea agentiei. Valoarea daunelor este limitata la valoarea neta a comenzii.</li>
            </ol>
           </p> <br />
           <p className="cookie_p"><strong>Social Media de marketing</strong><br />
                Inainte ca Clientul sa atribuie un contract, Agentia va indica in mod explicit Clientului ca termenii si conditiile furnizorilor de „canale de socializare” 
                (cum ar fi Facebook, in continuare „Furnizori”) isi rezerva dreptul de a respinge sau elimina reclame din orice motiv. Prin urmare, acesti Furnizori nu sunt obligati 
                sa transfere continut si informatii utilizatorilor. In consecinta, exista riscul necalculabil ca publicitatea sa fie eliminata fara motiv. Desi Furnizorii permit
                contraargumentele in cazul in care un alt utilizator depune o plangere, continutul va fi totusi indepartat imediat in aceste cazuri. In aceste evenimente, este posibil 
                sa dureze ceva timp pentru a restabili conditiile initiale si legale. Agentia isi desfasoara activitatile si executa contractul Clientului pe baza acestor conditii de 
                utilizare a Furnizorilor care nu sunt sub controlul agentiei. In contextul atribuirii unui contract, Clientul recunoaste in mod explicit ca acesti termeni de utilizare 
                (co) determina drepturile si obligatiile relatiei contractuale, daca exista. Agentia intentioneaza sa execute contractul Clientului in ceea ce priveste cunostintele si 
                credintele sale in conformitate cu politicile canalelor de social media. Pe baza conditiilor de utilizare valabile in prezent si a modalitatii usoare pentru utilizatori
                de a afirma incalcari si de a elimina continutul, Agentia nu poate garanta ca campania contractata poate fi recuperata in orice moment.
           </p> <br />
           <p className="cookie_p"><strong>Forma scrisa</strong><br />
                Modificarile si modificarile acestor Termeni si ale contractului sau ale altor elemente ale contractului se fac in scris. Acest lucru se aplica, de asemenea, 
                oricarei renuntari la cerinta scrisa. Partile nu au ajuns la niciun acord verbal, iar aceste acorduri sunt, prin urmare, inadmisibile.
           </p> <br />
           <p className="cookie_p"><strong>Anulabilitate</strong><br />
                In cazul in care orice prevedere a contractului sau a acestor Termeni este sau nu devine nula, total sau partial, acest lucru nu afecteaza valabilitatea termenilor 
                ramasi din prezentul regulament. Un termen care este invalid total sau partial este inlocuit cu un termen care reflecta cel mai aproape continutul sau economic.
           </p> <br />
           <p className="cookie_p"><strong>Alegerea Legii</strong><br />
                Toate relatiile contractuale si toate drepturile, obligatiile si drepturile reciproce aferente intre Agentie si Client sunt reglementate si interpretate exclusiv
                in conformitate cu dreptul substantial austriac, pana la excluderea normelor sale de conflict de drept si a Conventiei ONU de vanzare.
           </p> <br />
           <p className="cookie_p"><strong>Locul prestatiei si locul de desfasurare</strong><br />
                Locul executarii este domiciliul agentiei. In cazul in care serviciul este expediat, riscul se va transfera Clientului imediat ce agentia a predat marfurile 
                transportatorului pe care l-a ales. Toate litigiile care apar intre Agentie si Client in legatura cu aceasta relatie contractuala sunt trimise instantei care are
                competenta pentru Bucuresti. In pofida celor de mai sus, Agentia poate, de asemenea, sa dea in judecata Clientul la sediul legal general al Clientului.
           </p> <br />
           <p className="cookie_p space-footer">Asa cum a fost modificat in ianuarie 2020</p><br /><br />
      </div>
    </div>
  );
};

export default TermsAndConditions;

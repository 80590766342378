import '../css/describe-app.css';
// import SingleApp from './SingleApp';
import { useParams, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../state/languageContext';
// import { useContext } from 'react';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';

const DescribeApp = ({ isLightMode }) => {
  const { language } = useContext(LanguageContext);
  // const [apps, setApps] = useState([]);
  const [app, setApp] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/apps', {
        params: {
          slug,
        },
      })
      .then(res => {
        if (res.data.length) {
          setApp(res.data[0]);
        } else {
          navigate('/not-found');
        }
      });
  }, []);

  console.log(app);

  return (
    app && (
      <div id="describe-app">
        <div className="hero-container">
          <div className="right-container">
            <h3 className="title-app">{app.slug}</h3>
            <h2 className="short-description">{app.acf.short_description}</h2>
          </div>

          <div className="left-container">
            <img className="describeImg1" src={app.acf.cover_image.url} />
            <img className="describeImg2" src={app.acf.gif.url} />
          </div>
        </div>
        <div className="description" dangerouslySetInnerHTML={{ __html: app.acf.long_description }}></div>

        <section id="contact-us">
          <h3 id="get-in-touch" className="left-text section-title">
            {' '}
            {language.services.contact_us.line1}
          </h3>
          <div className="contact fix-contact">
            <a href="contact">
              <p className="float-text service-contact "> {language.services.contact_us.line2}</p>
            </a>
            <a href="contact">
              <img src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} />{' '}
            </a>
          </div>
        </section>
      </div>
    )
  );
};

export default DescribeApp;

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';


const WebWorkMobile = () => {
  const projects = [
    {
      src: 'wmschauffeursdiensten.nl.png',
    },
    {
      src: 'buenasado.ro.png',
    },
    {
      src: 'lotuschinesetherapy.ro.png',
    },
    {
      src: 'mojar.ro.png',
    },
    {
      src: 'museum.ro.png',
    },
    {
      src: 'playa9.ro.png',
     },
    {
       src: 'byentourage.jpg',
     },
    {
      src: 'beautybase.jpg',
    }
  ]

  return (
      <Swiper 
        modules={[Pagination]}
        pagination
        spaceBetween={15}
        slidesPerView={1}
        centeredSlides={true}
      > 
          { 
            projects.map((project, index) => {
              return (
                <SwiperSlide key = {index} className="swiper-slide swiper-slide-dark">
                  <img src={ `imagini/projects/${project.src}` } style={{ width: '100%' }} />
                </SwiperSlide>
              )
            })
          }
      </Swiper>
  );
};
export default WebWorkMobile;
import TypeIt from "typeit-react";
import { LanguageContext } from "../../../state/languageContext";
import { useContext } from "react";

const Companies = () => {
  const {language} = useContext(LanguageContext);
  const typeit_options = { 
    strings: [`${language.homepage.companies.description.typeit.string1}`,`${language.homepage.companies.description.typeit.string2}`,""],
    speed: 200,
    startDelay: 500,
    deleteSpeed: 100,
    nextStringDelay: 2000,
    breakLines: false,
    lifeLike: true,
    waitUntilVisible: true,
    loop: true,
    loopDelay: 1500
  }
  return (
    <div className="companies">
      <h2 className="left-text" id="important-text">{language.homepage.companies.title}</h2>
      <p className=" align">{language.homepage.companies.description.line1}<br /> {language.homepage.companies.description.line2} &nbsp; 
      <TypeIt 
        className="has-text-link typewriter3 type-services"
        options={ typeit_options }/>
      <br />{language.homepage.companies.description.line3}<br />
      </p>
    </div>
  );
};

export default Companies;

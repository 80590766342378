import Comments from "../components/ArticlePage/Comments/Comments";
import Content from "../components/ArticlePage/Content";

import "../css/articlePage.css";

const ArticlePage = () => {
  return (
    <div className="container d-flex justify-content-center" id="article-page">
      <article>
        <div className="titleBlog">
          <h2 id="title">Blog</h2>
        </div>

        <Content />

        {/* <Comments /> */}
      </article>
    </div>
  );
};

export default ArticlePage;

import { Link } from "react-router-dom";
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";

const Footer = () => {
  let currentYear = new Date().getFullYear();
  const {language} = useContext(LanguageContext);

  return (
    <footer className="page-footer">
      <div className="rowFooter">
        <div className="social-media">
          <a href="https://www.facebook.com/wolfdigitalmedia.ro/"><i className="fab fa-facebook-f fa-3x"></i></a>
          <a href="https://www.instagram.com/wolfdigitalmedia/"><i className="fab fa-instagram fa-3x"></i></a>
          <a href="https://www.linkedin.com/company/wolfdigitalmedia"><i className="fab fa-linkedin fa-3x"></i></a>
        </div>
        <div className="footer-text">
          <p id="footer-text" className="alignleft">&copy; <span id="time">{ currentYear }</span> {language.footer.credits}</p>
          <Link to="/privacy-policy"><p className="alignright" id="alignright"> {language.footer.privacy_policy}</p></Link>
          <Link to="/terms-and-conditions"><p className="alignright" id="alignTc">T & C</p></Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

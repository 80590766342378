const en = {
  // translation type
  "translation_type": "en",

  // header
  "header": {
    "homepage": "Home",
    "services": "Services",
    "work": "Work",
    "career": "Career",
    "blog": "Blog",
    "contact": "Contact"
  },

  // homepage
  "homepage": {
    "hero": {
      "description": {
        "line1": "We make",
        "typeit": {
          "string1": "scalable",
          "string2": "user-friendly",
        },
        "line3": "web and mobile apps!",
      },
      "contact_us": {
        "line1": "Get in touch",
        "line2": "Contact us for potential projects"
      }
    },
    "mobile_apps": {
      "title": "Mobile Apps",
      "description":{
        "line1" : "We use",
        "line2": "to create beautiful apps."
      }
    },
    "our_stacks": {
      "title": "Our Stacks"
    },
    "cases": {
      "title": "WE'VE GOT MORE",
      "look": "Take a look at our case studies"
    },
    "companies": {
      "title": "E-SHOPS AND COMPANIES WEBSITES",
      "description": {
        "line1": "Exploring the intersections",
        "line2": "between",
        "typeit": {
          "string1": "design",
          "string2": "digital technology",
        },
        "line3": "and content marketing for brands"
      }
    },
    "web_work": {
      "title": "OUR WEB WORK",
      "arrow": {
        "show": "See",
        "more": "more",
        "less": "less"
      }
    },
    "clients": "SOME OF OUR CLIENTS",
    "footer_home": {
      "contact_us": {
        "line1": "GET IN TOUCH",
        "line2": "Contact us for potential projects"
      },
      "join": {
        "line1": "LOOKING FOR A NEW CHALLENGE?",
        "line2": "We currently have 1 open position"
      }
    }
  },

  // services
  "services":{
    "hero": {
      "title": "Our Services",
      "description": {
        "line1": "We help",
        "typeit": {
          "string1": "companies",
          "string2": "brands",
        },
        "line3": "stand out",
        "line4": "in the digital age.",
      },
    },
    "our_services": "Our departments",
    "contact_us": {
      "line1": "Get in touch",
      "line2": "Contact us for potential projects"
    }
  },

  // work
  "work":{
    "hero": {
      "title": "Our Work",
      "description":{
        "line1": "Some highlights of work",
        "line2": "we've done for forward",
        "line3": "thinking clients.",
      }
    },
    "our_apps": "MOBILE APPS",
    "our_work": "WEBSITES AND WEB APPS",
    "contact_us": {
      "line1": "GET IN TOUCH",
      "line2": "Contact us for potential projects"
    }
  },

  //career
  "career": {
    "hero":{
      "title" : "Career",
      "description": {
        "line1": "Alright then. You're on your",
        "line2": "way to future digital",
        "line3": "masterpieces",
      },
      "open_position": "Currently 1 open position is available."
    },
    "goToJobDescription": {
      "apply": "Apply",
      "job_position": "Junior Flutter Developer"
    }
  },

  //job-description
  "jobDevDescription": {
    "hero": {
      "title": "Life as a Junior Flutter Developer",
      "description": "As a Junior Flutter Developer, you will work inside a multi-disciplinary team developing cross-platform mobile applications for our clients. You will be involved in the whole project lifecycle, from the ideation stage to final delivery."
    },
    "must_have": {
      "title": "Must have:",
      "skills": {
        "line1": "Experience working with Flutter ( must be demonstrated with personal projects )",
        "line2": "Experience working with Git",
        "line3": "Eagerness to learn"
      }
    },
    "nice_to_have": {
      "title": "Nice to have:",
      "skills": {
        "line1": "Experience working with Firebase, preferably alsongside Flutter",
        "line2": "Experience with Flutter state management tools like MobX",
        "line3": "Basic knowledge of RESTful APIs"
      }
    },
    "goToJobApply": {
      "job_position": "Junior Flutter Developer",
      "job_apply": "Apply for the job"
    }
  },

  //job-apply
  "job_apply": {
    "title": "Apply for the job!",
    "form_apply":{
      "name": "Name",
      "error_name": "Name is required.",
      "error_valid_name": "Name is not valid.",
      "email": "Email",
      "error_email": "Email is required.",
      "error_valid_email": "Email is not valid.",
      "phone": "Phone",
      "error_phone": "Phone number is required.",
      "error_valid_phone": "Phone number is not valid.",
      "message": "Message",
      "error_message": "Message is required.",
      "files_allowed": "Sorry, only JPG, JPEG, PNG, GIF & PDF files are allowed",
      "error_recaptcha": "You forgot to check.",
      "submit_btn": "Send"
    }
  },

  //blog
  "blog": {
    "hero": {
      "title": "Blog",
      "description": "Now you reached our blog section. Stay close for a new weekly article."
    },
    "post_on": "Post on",
    "by":"by",
    "subscribe_form": {
      "title": "SUBSCRIBE",
      "description": "Subscribe to Our newsletter for Updates every week",
      "email": "Your Email",
      "submit_btn" : "SUBSCRIBE"
    },
    "popular_post":"POPULAR POSTS",
    "categories":"CATEGORIES"
  },

  //article
  "article":{
    "post_on": "on",
    "by":"Post by",
    "comments":"COMMENTS",
    "leave_comment":{
      "title":"LEAVE A COMMENT",
      "name":"Name...",
      "email": "Email...",
      "message": "Your message...",
      "send_comment": "SEND COMMENT"
    },
    "previous":"Previous article",
    "next":"Next article"
  },

  //contact
  "contact": {
    "title": "Drop by for a coffee?",
    "visit_us": {
      "title": "Pay us a visit",
      "address": "Street Scoala Floreasca No 38"
    },
    "contact_form": {
      "title": "Get in touch",
      "name": "Name",
      "email": "Email",
      "subject": "Subject",
      "message": "Message",
      "submit_btn": "Send"
    }
  },

  // footer
  "footer": {
    "credits": "by Wolfdigitalmedia. All rights reserved.",
    "privacy_policy": "Privacy Policy"
  },
}

export default en;
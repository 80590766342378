import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";


const SwiperMobile = () => {
  const {language} = useContext(LanguageContext);
  const departments = [
    {
      title_ro: 'Strategii de crestere',
      title_en:'Strategy and Creativity',

      desc_ro: `Strategie digitala
      Activari de brand
      Strategia de crestere
      Concepte creative
      Modele de afaceri`,
      desc_en: `Digital Strategy
      Brand Activations
      Growth Strategy
      Creative Concepts
      Business Models`
    },
    {
      title_ro: 'Proiectare si experienta utilizatorului',
      title_en:'Design and User Experience',
      
      desc_ro: `Proiectarea interfeței cu utilizatorul
      Experiența utilizatorului
      Arhitectura informaționala
      Branding digital
      Wireframing & Prototyping`,
      desc_en: `Business Models
      User Experience (UX)
      Information Architecture
      Digital Branding
      Wireframing & Prototyping`
    },
    {
      title_ro: 'Dezvoltare si tehnologie',
      title_en:'Development and Technology',
      
      desc_ro: ` Dezvoltare web
      Sisteme de gestionare a continutului
      Solutii de comert electronic
      Design web responsive
      Asigurarea calitatii`,
      desc_en: `Web Development
      Content Management
      Systems
      E-Commerce Solutions
      Responsive Web Design
      Quality Assurance`
    },
    {
      title_ro: 'Modele de afaceri si comert electronic',
      title_en:'Business Models and e/m-commerce',
      
      desc_ro: `Solutii de comert electronic
      Instrumente de personalizare
      Optimizare canal
      Comert social`,
      desc_en: `E-Commerce Solutions
      Customization Tools
      Growth Hacking
      Funnel Optimization
      Social Commerce`
    },
    {
      title_ro: 'Aplicatii si mobil',
      title_en:'Apps and Mobile',
      
      desc_ro: `Aplicatii pentru IOS si Android
      Strategie de brand mobil
      Inovatie pentru mobil
      Solutii de realitate virtuala`,
      desc_en: `iPhone & iPad
      Applications
      Mobile Brand Strategy
      Mobile Innovation
      Virtual Reality Solutions`
    },
    {
      title_ro: 'Retele sociale',
      title_en:'Social Media',
      
      desc_ro: `Strategia de retele media
      Administrarea comunitatii
      Influencer Marketing
      Activari de Brand`,
      desc_en: `Social Media Strategy
      Community Management
      Influencer Marketing
      Social Brand Activations`
    },
    {
      title_ro: 'Performanta si media',
      title_en:'Performance and Media',
      
      desc_ro: `SEO & SEM
      Socia Marketing
      Performance Marketing
      Planificarea media digitala`,
      desc_en: `SEO & SEM
      Social Marketing
      Growth Hacking
      Performance Marketing
      Digital Media Planning`
    },
    {
      title_ro: 'Continut si productie',
      title_en:'Content and Production',
      
      desc_ro: `Strategia de continut
      Planificare editoriala
      Productii foto
      Productii video
      Identitate de Brand`,
      desc_en: `Content Strategy
      Editorial Planning
      Photo Productions
      Video Productions
      Social Brand Identities`
    },
  ]

  return (
    <Swiper 
      modules={[Pagination]}
      pagination
      spaceBetween={15}
      slidesPerView={1}
      centeredSlides={true}
      className="swiper-container"
    > 
        {
          departments.map((department, index) => {
            return (<SwiperSlide key = {index}>
              <div className="swiper-slide-header">
               {language.translation_type == "ro" ? department.title_ro : department.title_en}
              </div>
              <div className="desc-slide">                            
              {language.translation_type == "ro" ? department.desc_ro : department.desc_en}
              </div>
            </SwiperSlide> )
          })
        }

    </Swiper>
    
  );
};

export default SwiperMobile;
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';

const MobileAppsDesktop = () => {
  const mobile_apps = [
    {
      name: 'Wonderbaby',
      src: 'Wonderbaby.gif'
    },
    {
      name: 'Ality',
      src: 'ality.gif'
    },
    {
      name: 'Zooft',
      src: 'zooft.gif'
    },
    {
      name: 'FastShop',
      src: 'shopfast.gif'
    },
    {
      name: 'Daytasky',
      src: 'daytasky.gif'
    },
  ]

  return (
    <Swiper
      modules={[Navigation]}
      navigation
      spaceBetween={10}
      slidesPerView={1}
      centeredSlides={true}
      id="mobile-apps-desktop" 
      style={{ textAlign: 'center' }}
    >
      {
        mobile_apps.map((app, index) => {
          return (
            <SwiperSlide key={index}>
              <p className="gif-text-left">{ app.name }</p>
              <img src={`imagini/${ app.src }`} alt="" style={{ width: '44%' }} />
            </SwiperSlide>
          );
        })
      }
    </Swiper>
  );
};

export default MobileAppsDesktop;

import axios from "axios";
import { useState, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { LanguageContext } from "../../../state/languageContext";

const ContactForm = () => {
  // Language object
  const {language} = useContext(LanguageContext);

  function strrev(str){
    let dateArr = str.split('-');
    let n = dateArr.length;
    let newArr = [];
    for(let i = n - 1; i >= 0; i--){
      newArr.push(dateArr[i]);
    }

    return newArr.join('/'); //separatorul final
  }

  const [isRecaptchaValid, setRecaptchaValid] = useState(false);

  function validateForm(e){
    e.preventDefault();
    const form = document.forms['contact-form'];

    const name = form['name'].value;
    const email = form['email'].value;
    const subject = form['subject'].value;
    const message = form['message'].value;

    if(!name || !email || !subject || !message || !isRecaptchaValid)
      return false

    const dateObject = new Date();
    const dateReversed = dateObject.toISOString().split('T')[0];
    const date = strrev(dateReversed);
    const time = `${dateObject.getHours()}:${dateObject.getMinutes()}`;

    axios.post(`${process.env.REACT_APP_SERVER_URL}/send-contact-message`, { name, email, subject, message, date, time })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    })
  }

  return (
    <form onSubmit={validateForm} className="contact-form" id="contact-form" name="contact-form">
      <div className="styled-input1 wide1">
        <input type="text" className="inputApply" id="name" name="name" autoComplete="on" />
        <label>{language.contact.contact_form.name}</label>
        <span></span>
      </div> 

      <div className="styled-input1 wide1">
        <input type="email" className="inputApply" id="email" name="email" autoComplete="on" />
        <label>{language.contact.contact_form.email}</label>
        <span></span>
      </div>

      <div className="styled-input1 wide1">
        <input type="text" className="inputApply" id="subject" name="subject" autoComplete="on" />
        <label>{language.contact.contact_form.subject}</label>
        <span></span>
      </div>

      <div className="styled-input1 wide1">
        <textarea className="inputApply" id="message" name="message" autoComplete="off"></textarea>
        <label>{language.contact.contact_form.message}</label>
        <span></span>

        <ReCAPTCHA sitekey="6Le4A2MeAAAAANKeUSpk1m4Y5aPUbtwIpjWF02DZ" onChange={() => setRecaptchaValid(true)} />
      
        <div className="button-careers">
          <button type="submit" name="submit" value="submit" className="btn-apply button-text float-text-btn">{language.contact.contact_form.submit_btn}
          </button>
          <img style={{ paddingTop: '16px' }} src="imagini/baseline_arrow_right_alt_white_24dp.png" alt="send-message-arrow" className="arrow-right" />
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
import Article from './Articles/Article';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../../state/languageContext';

const Articles = () => {
  const { language } = useContext(LanguageContext);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    let tempArr = [];

    function dateReverse(date) {
      let dateArr = date.split('-');
      let n = dateArr.length;
      let newArr = [];
      for (let i = n - 1; i >= 0; i--) {
        newArr.push(dateArr[i]);
      }

      return newArr.join('/'); //separatorul final
    }

    axios.get('https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/posts').then(response => {
      response.data.forEach(article => {
        let tempArticle = {
          id: article.id,
          slug: article.slug,
          title: language.translation_type == 'en' ? article.title.rendered : article.acf.titlu_ro,
          date: dateReverse(article.date.split('T')[0]),
          description: language.translation_type == 'en' ? article.excerpt.rendered : article.acf.descriere_ro,
          media: article.featured_media,
          categories_ids: article.categories,
          author_id: article.author,
        };

        tempArr.push(tempArticle);
      });

      setArticles(tempArr);
    });
  }, []);

  return (
    <>
      {articles.map(article => (
        <Article key={article.id} title={article.title} date={article.date} description={article.description} media={article.media} categories_ids={article.categories_ids} author_id={article.author_id} slug={article.slug} />
      ))}
    </>
  );
};

export default Articles;

//autor

import Articles from "./Content/Articles";
import Subscribe from "./Content/Subscribe";
import PopularPosts from "./Content/PopularPosts";
import CategoriesList from "./Content/CategoriesList";

const Content = () => {
  return (
    <div id="blog-page-content" className="container d-flex justify-content-center mobil">
      <div className="col-lg-8">
        <Articles />
      </div>

      <div className="col-lg-4">
        <Subscribe />

        <PopularPosts />

        {/* <CategoriesList/> */}
      </div>
    </div>
  );
};

export default Content;

import '../css/contact.css'
import Map from '../components/Contact/Map'
import Content from '../components/Contact/Content';
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";

const Contact = () => {
  // Language object
  const {language} = useContext(LanguageContext);
  
  return (
    <div id="contactPage">
      <div className="harta">
        <h1 className="contact-fix section-title">{language.contact.title}</h1>
        <Map />
      </div>

      <Content />
    </div>
  );
};

export default Contact;
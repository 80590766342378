import '../css/error404.css'

const Error404 = () => {
  return (
    <div id="error-404-page">
      <div className="notfound">
        <div className="notfound-404">
          <h1>4<span>0</span>4</h1>
        </div>
        <p className="text-404">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
      </div>
    </div>
  );
};

export default Error404;

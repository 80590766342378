import '../css/home.css'
import 'swiper/css/bundle'

import Hero from '../components/Homepage/Hero'
import MobileApps from '../components/Homepage/MobileApps'
import Stacks from '../components/Homepage/Stacks'
import GotMore from '../components/Homepage/GotMore'
import WebWork from '../components/Homepage/WebWork'
import WebWorkMobile from '../components/Homepage/WebWorkMobile'
import Clients from '../components/Homepage/Clients'
import FooterHome from '../components/Homepage/FooterHome'
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";



const Home = ({ isLightMode }) => {
  const {language} = useContext(LanguageContext);
  return (
    <div id="homepage">
      <Hero  isLightMode={ isLightMode }/>
      <MobileApps />

      <section className="our-stacks">
        <div className="row">
          <h2 className="important-text left-text">{language.homepage.our_stacks.title}</h2>
        </div>
        
        <Stacks />
      </section>

      <GotMore isLightMode={ isLightMode }/>

      <div className="row">
        <h3 className="important-text left-text" id="ourApps">{language.homepage.web_work.title}</h3>
      </div>

      <WebWork isLightMode={ isLightMode }/>

      <section id="mobile-apps-swiper">
        <WebWorkMobile/>
      </section>

      <Clients />

      <FooterHome isLightMode={ isLightMode } /> 

    </div>
  );
};

export default Home;

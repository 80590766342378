import '../css/work.css'
import 'swiper/css/bundle'

import OurAppsDesktop from '../components/Work/Swiper/OurAppsDesktop';
import OurAppsMobile from '../components/Work/Swiper/OurAppsMobile';
import WebApps from '../components/Work/WebApps';
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";

const Work = ({ isLightMode }) => {
  const {language} = useContext(LanguageContext);
  return (
    <div id="work-page">
      <section className="first-line">
        <h1 className="title-page" >{language.work.hero.title}</h1>
        <p className="description">
        {language.work.hero.description.line1}<br /> {language.work.hero.description.line2} <br />{language.work.hero.description.line3}
        </p>
      </section>

      <h2 className="our-work section-title">{language.work.our_apps}</h2>

      <section className="container-gif-gallery">
        <OurAppsDesktop />
      </section>

      <section id="swiper-mobile">
        <OurAppsMobile />
      </section>
      
      <h3 className="our-work section-title">{language.work.our_work}</h3>

      <WebApps />

      <section className="contact-us">
        <h3 id="contact-us-title">{language.work.contact_us.line1}</h3>

        <div className="contact fix-contact">
          <a href="/contact">
            <p className="float-text service-contact ">{language.work.contact_us.line2}</p>
          </a>
          <a href="/contact">
            <img src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} /> 
          </a>
        </div>
      </section>
    </div>
  );
};

export default Work;

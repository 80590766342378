const Department = ({ title, desc }) => {
  return (
    <div className="grid-container-context">
      { title }
      <div className="middle">
        <p className="grid-container-text">
        { desc }
        </p>
      </div>
    </div>
  );
};

export default Department;
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";

const FooterHome = ({ isLightMode }) => {
  const {language} = useContext(LanguageContext);
  return (
    <section className="footer-home">
      <div className="footer-home-subtext" >
        <h3 id="get-in-touch" className="important-text">{language.homepage.footer_home.contact_us.line1}</h3>
        <div className="contact">
          <a href="/contact">
            <p className="text-arrow service-contact">{language.homepage.footer_home.contact_us.line2}</p>
            <img src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} />
          </a>
        </div>
      </div>
      <div className="footer-home-subtext1">
        <h3 id="get-in-touch" className="important-text">{language.homepage.footer_home.join.line1}</h3>
        <div className="contact">
          <a href="/cariera">
            <p className="text-arrow service-contact">{language.homepage.footer_home.join.line2}</p>
            <img src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default FooterHome;

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  // redirect daca nu e logat
  useEffect(() => {
    if (!window.localStorage.getItem('loggedIn') || !window.localStorage.getItem('username')) {
      navigate('/login', { replace: true });
    }
  });

  const username = localStorage.getItem('username');

  const logout = () => {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    navigate('/login', { replace: true });
  };

  return (
    <div id="welcome-page">
      <div className="page-header">
        <h1>
          Salut, <b className="username">{username}</b>. Bine ai venit pe site-ul nostru!
        </h1>
      </div>
      <div className="buttons">
        <Link to="/database" className="btn btn-see">
          Vezi baza de date
        </Link>
        <a onClick={logout} className="btn btn-danger">
          Delogare
        </a>
      </div>
    </div>
  );
};

export default Welcome;

import Service from './ServicesComponent/Service.js';
import { LanguageContext } from "../../state/languageContext";
import { useContext } from 'react';

const ServicesSection = () => {
  const service = [
    {
      title_ro: 'Branding si Identitate',
      title_en: 'Branding and Identity',
      desc_ro:'Construirea unei identități de brand este un efort strategic multidisciplinar si fiecare element trebuie sa sprijine mesajul general si obiectivele de afaceri.',
      desc_en:'Building a brand identity is a multi-disciplinary strategic effort, and every element needs to support the overall message and business goals.'
    },
    {
      title_ro: 'Strategie, Idee si Concept',
      title_en: 'Strategy, Idea and Concept',
      desc_ro: 'Procesul strategic incearca sa inteleaga problemele, mediul, concurenta, clientii si perspectivele.',
      desc_en:'The strategic process tries to understand the issues, the environment, the competition, the customers, and the insights.'
    },
    {
      title_ro: 'Dezvoltare de aplicatii si Web',
      title_en: 'App and Web Development',
      desc_ro: 'Suntem pasionati de crearea de solutii software extraordinare care creeaza valoare pentru afacerea dvs. si duc la succes.',
      desc_en:'We are passionate about creating ousstanding software solutions that create value for your business and lead to success.'
    },
    {
      title_ro: 'UX & UI Design',
      title_en: 'UX & UI Design',
      desc_ro: 'Fuzionarea experientei utilizatorului cu interfata utilizatorului pentru a crea experiente frumoase si captivante.',
      desc_en:'Merging User Experience with User Interface to create beautiful and immersive experiences.'
    }
  ]
  const {language} = useContext(LanguageContext);

  return (
    <section className="second-line">
       { 
        service.map((service, index) => <Service key={index} title={language.translation_type == 'ro' ? service.title_ro : service.title_en} desc={language.translation_type == 'ro' ? service.desc_ro : service.desc_en} />)
      }
    </section>
  );
};

export default ServicesSection;

import ContactForm from "./Content/ContactForm";
import VisitUs from "./Content/VisitUs";
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";

const Content = () => {
  // Language object
  const {language} = useContext(LanguageContext);

  return (
    <section className="contanerContact">
      <VisitUs />

      <div className="contact-section">
        <h2 id="h2-contact-section">{language.contact.contact_form.title}</h2>

        <ContactForm />
      </div>

      <iframe name="hiddenFrame" width="0" height="0" border="0" style={{ display: 'none' }}></iframe>
    </section>
  );
};

export default Content;

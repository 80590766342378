import TypeIt from 'typeit-react';
import MobileAppsDesktop from './MobileApps/MobileAppsDesktop';
import MobileAppsMobile from './MobileApps/MobileAppsMobile';
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";

const MobileApps = () => {
  const {language} = useContext(LanguageContext);
  const typeit_options = {
    strings: ["Flutter", "Dart", "Swift", "ReactNative", "AndroidStudio"],
    speed: 200,
    startDelay: 500,
    deleteSpeed: 100,
    nextStringDelay: 2000,
    breakLines: false,
    lifeLike: true,
    waitUntilVisible: true,
    loop: true,
    loopDelay: 1500
  }

  return (
    <section id="mobile-apps">
      <div className="mobile-apps-desktop">
        <div className="row">
          <h2 className="important-text">{language.homepage.mobile_apps.title}</h2>
        </div>

        <div className="apps">
          <p id="typewriteID">
            <span>{language.homepage.mobile_apps.description.line1}</span>
            <span> <TypeIt options={typeit_options}/> </span>
            {language.homepage.mobile_apps.description.line2}
          </p>
          <div className="container-gif-gallery" style={{ width: '50%' }}> 
            <MobileAppsDesktop />
          </div>
        </div>
      </div>

      {/* mobile swiper */}
      <div id="mobile-apps-swiper">
        <MobileAppsMobile />
      </div>
    </section>
  );
};

export default MobileApps;

import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { LanguageContext } from "../../state/languageContext";
import Author from "../Blog/Content/Articles/Author";
import Categories from "../Blog/Content/Articles/Categories";
import Media from "./Components/Media";
import BeatLoader from "react-spinners/BeatLoader";

const Content = () => {
  const [ loading, setLoading ] = useState(true);
  const {language} = useContext(LanguageContext);
  const [article, setArticle] = useState();
  const [previousArticle, setPreviousArticle] = useState('');
  const [nextArticle, setNextArticle] = useState('');
  function dateReverse(date){
    let dateArr = date.split('-');
    let n = dateArr.length;
    let newArr = [];
    for(let i = n - 1; i >= 0; i--){
      newArr.push(dateArr[i]);
    }

    return newArr.join('/'); //separatorul final
  }
  useEffect(() => {
    async function getMedia(){
      const response = await axios .get(`https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/posts?slug=${window.location.pathname.replace("/blog/", "")}`)
      setArticle(response.data[0]);
      setLoading(false);
      if(response.data[0].previous)
        setPreviousArticle(response.data[0].previous.slug);
      if(response.data[0].next)
        setNextArticle(response.data[0].next.slug);
    }
    getMedia();
  }, []);
 
  return ( article == null ? <BeatLoader color={"white"} loading={loading} size={30} /> :
    <div className="article" key={article.id}> 
      <Media media_id={article.featured_media}/>
      <div className="text">
      <span className="textTitle" dangerouslySetInnerHTML={{ __html: language.translation_type == "en" ? article.title.rendered : article.acf.titlu_ro }}></span>
        <div className="category">
          <span className="brown">{language.article.by}</span> <Author author_id={article.author}/> &nbsp;
          <span className="brown">{language.article.post_on}</span> {dateReverse(article.date.split("T")[0])}&nbsp;
          <span className="brown">in</span> <Categories categories_ids={article.categories}/> 
        </div>
      <div className="articleContent" dangerouslySetInnerHTML={{__html: language.translation_type == "en" ? article.content.rendered : article.acf.continut.replaceAll('\r\n','<br/>')}}></div>
        <div className="share">
          <a
            className="shareFb"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener"
          >
            <img className="shareImg" src='/imagini/facebook.png'/>
          </a>
          <a
            className="shareTw"
            href={`http://www.twitter.com/share?url=${window.location.href}`}
            target="_blank"
            rel="noopener"
          >
            <img className="shareImg" src="/imagini/twitter.png" />
          </a>
        </div>
      </div>
      <div className="footerArticle">
        {
          previousArticle ? (
            <a href={`/blog/${previousArticle}`} className="previous">
              <i className="bi bi-arrow-left"></i>
              <span> {language.article.previous} </span>
            </a>
          ) : <div> </div>
        }
        {
          nextArticle ? (
            <a href={`/blog/${nextArticle}`} className="next">
              <span>{language.article.next} </span>
              <i className="bi bi-arrow-right"></i>
            </a>
          ) : <div> </div>
        }
      </div>
    </div>
  );
};

export default Content;

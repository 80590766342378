import { LanguageContext } from "../../../state/languageContext";
import { useContext } from "react";
import axios from "axios";

const Subscribe = () => {
  // Language object
  const {language} = useContext(LanguageContext);

  function isEmailValid(email) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(regex)) return true;
    return false;
  }
  
  function validateForm(e){
    e.preventDefault();

    const form = document.forms['subscribe_form'];
    const email = form['email'].value;
    const notValid= document.getElementById('notValid');

    if (!email || !isEmailValid(email)) {
      notValid.style.display= "block";

      return false;
    } 
    else {
      notValid.style.display= "none";
      form['email'].value = '';
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}/subscribe`, { email })
    .then(res => {
      console.log(res);
      const valid= document.getElementById('valid');
      valid.style.display= "block";
    })
    .catch(err => {
      console.log(err);
    })
  }

  return (
    <div className="col-lg-12">
      <form id="aside1" onSubmit={validateForm} name='subscribe_form'>
        <div className="card" id="cardBlog">
          <div className="card-body" id="cardBody">
            <h5 className="card-title text-center titleS">{language.blog.subscribe_form.title}</h5>
            <p className="card-text textCard m-3">{language.blog.subscribe_form.description}</p>
            <input className="m-3 textS" type="text" name="email" placeholder={language.blog.subscribe_form.email} id="cardInput" />
            <p id="notValid">Please enter a valid email.</p>
            <p id="valid">You subscribed to our newsletter.</p> {/*display: none; */}
            <button type="submit" className="btn m-3" id="buttonS">{language.blog.subscribe_form.submit_btn}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Subscribe;

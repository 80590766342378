const Project = ({ title, src, desc }) => {
  return (
    <div className="container-gallery colorText">
      <img className="img-gallery" src={ `imagini/projects/${src}` } alt="imagine-portofoliu" />
      <span className="title-work">{ title }</span>
      <span className="text-gallery">{ desc }</span>
    </div>
  );
};

export default Project;

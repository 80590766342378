import CaseStudies from "./GotMore/CaseStudies";
import Companies from './GotMore/Companies';

const GotMore = ({ isLightMode }) => {
  return (
    <section className="got-more">
      <CaseStudies isLightMode={ isLightMode }/>
      
      <Companies />
    </section>
  );
};

export default GotMore;

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Database = () => {
  const navigate = useNavigate();

  const [contactMessages, setContactMessages] = useState([]);
  const [applyMessages, setApplyMessages] = useState([]);

  // redirect daca nu e logat
  useEffect(() => {
    if (!window.localStorage.getItem('loggedIn') || !window.localStorage.getItem('username')) {
      navigate('/login', { replace: true });
    }

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/database`,
      data: {
        username: window.localStorage.getItem('username'),
      },
    }).then(response => {
      if (response.data.shouldRedirect) navigate('/login', { replace: true });
    });

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER_URL}/database`,
    }).then(response => {
      if (response.data.contact && !contactMessages.length) setContactMessages(response.data.contact);

      if (response.data.apply && !applyMessages.length) setApplyMessages(response.data.apply);
    });
  });

  const logout = () => {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    navigate('/login', { replace: true });
  };

  return (
    <div id="database-page">
      <div className="page-header">
        <h1 className="title-database">MESAJE CONTACT</h1>
        <a onClick={logout} className="btn btn-danger" style={{ float: 'right', marginRight: '70px', marginBottom: '20px' }}>
          Delogare
        </a>
      </div>

      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>ORA</th>
              <th>DATA</th>
              <th>NUME</th>
              <th>EMAIL</th>
              <th>SUBIECT</th>
              <th>MESAJ</th>
            </tr>
          </thead>
          <tbody>
            {contactMessages.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.time}</td>
                  <td>{row.date}</td>
                  <td>{row.nume}</td>
                  <td>{row.email}</td>
                  <td>{row.subject}</td>
                  <td className="overflow">{row.mesaj}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <h1 className="title-database">APLICARI JOBURI</h1>
      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>ORA</th>
              <th>DATA</th>
              <th>NUME</th>
              <th>EMAIL</th>
              <th>TELEFON</th>
              <th>MESAJ</th>
              <th>Pozitie</th>
              <th>CV</th>
            </tr>
          </thead>
          <tbody>
            {applyMessages.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.time}</td>
                  <td>{row.date}</td>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.phone}</td>
                  <td className="overflow">{row.mesaj}</td>
                  <td>{row.job}</td>
                  <td>
                    <a href={row.cv} target="_blank">
                      Afiseaza CV
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Database;

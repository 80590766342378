import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

const Media = ({ media_id, slug }) => {
  const [ loading, setLoading ] = useState(true);
  const [url, setUrl] = useState("/imagini/Blog_Default.jpg");

  useEffect(() => {
    async function getMedia(){
      const imageResponse = await axios.get(`https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/media/${media_id}`)
      setUrl(imageResponse.data.guid.rendered);
      setLoading(false);
    }
    if (media_id) {
      getMedia();
    }
  }, []);
  return (
    <span className="col-lg-6 marginMobile">
      { loading ? ( <BeatLoader color={"black"} loading={loading} size={30} /> ) : (
        <Link to={`/blog/${slug}`}>
          <img src={url} className="imagePopular" />
        </Link>
      )}
    </span>
  );
};

export default Media;

const Service = ({ title, desc }) => {
  return (
    <div className="cnt">
      <p className="cnt-text">{ title }</p>
      <div className="overlay">
        <p className="text-overlay">{ desc }</p>
      </div>
    </div>
  );
};

export default Service;

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const OurAppsMobile = () => {
  return (
    <Swiper
      modules={[Pagination]}
      pagination
      spaceBetween={15}
      centeredSlides={true}
    >
      <SwiperSlide className="swiper-slide-dark">
        <img src="imagini/Wonderbaby.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-slide-dark">
        <img src="imagini/zooft.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-slide-dark">
        <img src="imagini/ality.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-slide-dark">
        <img src="imagini/daytasky.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-slide-dark">
        <img src="imagini/ROTrack.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>

      <SwiperSlide className=" swiper-slide-dark">
        <img src="imagini/shopfast.gif" alt="" style={{ width: '60%' }} />
      </SwiperSlide>
      
    </Swiper>
  );
};

export default OurAppsMobile;

import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../../state/languageContext';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import Author from './Author';
import Categories from './Categories';

const Article = ({ slug, title, date, description, media, categories_ids, author_id }) => {
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [url, setUrl] = useState('/imagini/Blog_Default.jpg');

  useEffect(() => {
    async function getMedia() {
      const imageResponse = await axios.get(`https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/media/${media}`);
      setUrl(imageResponse.data.guid.rendered);
      setLoading(false);
    }
    if (media) {
      getMedia();
    }
  }, []);

  return (
    <article style={{ backgroundColor: loading ? 'transparent' : '#fff' }} className="article">
      {loading ? (
        <BeatLoader color={'white'} loading={loading} size={30} />
      ) : (
        <Link to={`/blog/${slug}`} className="anchor">
          <img src={url} className="imageArticle" />
          <div className="d-flex flex-column align-items-center article-content">
            <h5 className="titleArticle1" dangerouslySetInnerHTML={{ __html: title }}></h5>
            <div className="category">
              <span className="brown">{language.blog.post_on} </span>
              {date} <span className="brown">{language.blog.by} </span>
              <Author author_id={author_id} />
              <span className="category">
                <span className="brown"> in </span>
                <Categories categories_ids={categories_ids} />
              </span>
            </div>
            <p className="articleText" dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </Link>
      )}
    </article>
  );
};

export default Article;

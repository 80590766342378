import axios from "axios";
import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const Media = ({media_id}) => {
  const [ loading, setLoading ] = useState(true);
  const [image, setImage] = useState("/imagini/Blog_Default.jpg");
  useEffect(() => {
    async function getMedia(){
      const imageResponse = await axios.get(`https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/media/${media_id}`)
      setImage(imageResponse.data.guid.rendered);
      setLoading(false);
    }
    if (media_id) {
      getMedia();
    }
  }, []);
  return (
    loading ? <div className="d-flex justify-content-center mt-5"> 
    <BeatLoader color={"black"} loading={loading} size={30}/> </div> :
    (image.length>0 ? <img id="articleImg" src={image} /> : <div></div>)
    
  );
};

export default Media;

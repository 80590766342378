import '../css/services.css'
import Hero from '../components/Services/Hero.js';
import ServicesSection from '../components/Services/ServicesSection'
import Departments from '../components/Services/Departments'
import SwiperMobile from '../components/Services/SwiperMobile'
import 'swiper/css'
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";

const Services = ({ isLightMode }) => {
  const {language} = useContext(LanguageContext);
  return (
    <div id="services-page">
      
      <Hero/>

      <ServicesSection/>

      <section className="services">
        <div>
          <h2 className="our-services-title"> {language.services.our_services}</h2>
        </div>

        <Departments/>

        {/* swiper mobile */}

        <div id="section-swiper">
          <SwiperMobile/>
        </div>
      </section>

      <section id="contact-us" >
        <h3 id="get-in-touch" className="left-text section-title"> {language.services.contact_us.line1}</h3>
        <div className="contact fix-contact">
          <a href="contact"><p className="float-text service-contact "> {language.services.contact_us.line2}</p></a>
          <a href="contact"><img src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} /> </a>
        </div>
      </section>
    </div>
  );
};

export default Services;

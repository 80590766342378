import '../css/blog.css'

import Hero from '../components/Blog/Hero';
import Content from '../components/Blog/Content';

const Blog = () => {
  return (
    <div className="master-container" id="blog-page">
      <Hero />

      <Content />
    </div>
  );
};

export default Blog;
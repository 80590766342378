import { LanguageContext } from "../../../state/languageContext";
import { useContext } from "react";

const VisitUs = () => {
  // Language object
  const {language} = useContext(LanguageContext);

  return (
    <div className="container2">
      <h2 id="h1-contact">{language.contact.visit_us.title}</h2>

      <p className="text-contact">
        <img src="imagini/baseline_phone_white_24dp.png" alt="" className="visit-us-link-icon" /> 
        <a className="color-link" href="tel:0769467442">
          <span>+40769467442</span>
        </a>
      </p>

      <p className="text-contact">
        <img src="imagini/baseline_email_white_24dp.png" alt="" className="visit-us-link-icon" />  
        <a className="color-link" href="mailto:office(@)wolfdigitalmedia.ro">
          <span>office@wolfdigitalmedia.ro</span>
        </a>
      </p>

      <p className="text-contact">
        <img src="imagini/baseline_home_white_24dp.png" alt="" className="visit-us-link-icon" />  
        <a className="color-link" href="#">
          <span>{language.contact.visit_us.address}</span>
        </a>
      </p>
    </div>
  );
};

export default VisitUs;

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const OurAppsDesktop = () => {
  return (
    <Swiper modules={[Navigation]} navigation spaceBetween={10} slidesPerView={2} id="swiper-container">
      <SwiperSlide className="swiper-app" style={{ maxWidth: '1000px' }}>
        <p className="gif-text-left">Wonderbaby</p>
        <img src="imagini/Wonderbaby.gif" alt="" style={{ width: '44%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-app" style={{ maxWidth: '1000px' }}>
        <div className="titleContainer">
          <p className="gif-text-left">Ality</p>
          <a href="/apps/ality">
            <p className="gif-text-left clickable">More Info</p>
          </a>
        </div>
        <img src="imagini/ality.gif" alt="" style={{ width: '44%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-app" style={{ maxWidth: '1000px' }}>
        <p className="gif-text-left">Zooft</p>
        <img src="imagini/zooft.gif" alt="" style={{ width: '44%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-app" style={{ maxWidth: '1000px' }}>
        <p className="gif-text-left">ShopFast</p>
        <img src="imagini/shopfast.gif" alt="" style={{ width: '44%' }} />
      </SwiperSlide>

      <SwiperSlide className="swiper-app" style={{ maxWidth: '1000px' }}>
        <p className="gif-text-left">Daytasky</p>
        <img src="imagini/daytasky.gif" alt="" style={{ width: '45%' }} />
      </SwiperSlide>
    </Swiper>
  );
};

export default OurAppsDesktop;

import { LanguageContext } from "../../../state/languageContext";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import Media from "./PopularPosts/Media";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

const PopularPosts = () => {
  const [ loading, setLoading ] = useState(true);
  // Language object
  const { language } = useContext(LanguageContext);

  const [articles, setArticles] = useState([]);
  useEffect(() => {
    let tempArr = [];
    function dateReverse(date){
      let dateArr = date.split('-');
      let n = dateArr.length;
      let newArr = [];
      for(let i = n - 1; i >= 0; i--){
        newArr.push(dateArr[i]);
      }
  
      return newArr.join('/'); //separatorul final
    }
    async function getArticles() {
      const response = await axios.get("https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/posts");
      response.data.forEach((article) => {
        let tempArticle = {
          id: article.id,
          slug: article.slug,
          title: language.translation_type == "en" ? article.title.rendered : article.acf.titlu_ro,
          date: dateReverse(article.date.split("T")[0]),
          media: article.featured_media,
        }
        tempArr.push(tempArticle);
        setLoading(false);
      })
      setArticles(tempArr);
    }
    getArticles();
  }, []);
  return (
    <div className="col-lg-12 d-flex justify-content-center mb-3">
      { loading ? ( <BeatLoader className="spinner" color={"white"} loading={loading} size={30} /> ) : (
      <aside id="aside2">
        <div className="card text-center">
          <div className="card-body">
            <h5 className="card-title text-center titleP">
              {language.blog.popular_post}
            </h5>
            {articles.slice(0,3).map((article) => {
              return (
                <div key={article.id} className="row mt-5 mb-5 center">
                  <Media media_id={article.media} slug={article.slug} />
                  <div className="col-lg-6">
                    <Link to={`/blog/${article.slug}`} className="anchor">
                      <p
                        className="textStart textP"
                        dangerouslySetInnerHTML={{ __html: article.title }}
                      ></p>
                      <p className="text-center para" className="category p-0">
                        <span className="brown">{language.blog.post_on}</span> {article.date}
                      </p>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </aside>)}
    </div>
  );
};

export default PopularPosts;

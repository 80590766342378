import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';

import Home from './views/Home';
import Services from './views/Services';
import Work from './views/Work';
import Career from './views/Career';
import AppDevDescription from './views/Career/AppDevDescription';
import JobApply from './views/Career/JobApply';
import Contact from './views/Contact';
import Blog from './views/Blog';
import ArticlePage from './views/ArticlePage';
import TermsAndConditions from './views/TermsAndConditions';
import PrivacyPolicy from './views/PrivacyPolicy';
import Login from './views/Dashboard/Login';
import Welcome from './views/Dashboard/Welcome';
import Database from './views/Dashboard/Database';
import Error404 from './views/Error404';

import Footer from './components/Footer';

import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

import { LightModeContext } from './state/lightModeContext';
import { LanguageContext } from './state/languageContext';
import { useEffect, useState } from 'react';

import en from './translations/en';
import ro from './translations/ro';
import DescribeApp from './views/DescribeApp';

function App() {
  let initialLightMode = localStorage.getItem('lightMode') == 'enabled' ? true : false;
  if (localStorage.getItem('lightMode') == 'enabled') {
    document.body.classList.add('white-mode-site');
  } else {
    document.body.classList.remove('white-mode-site');
  }

  const [lightMode, setLightMode] = useState(initialLightMode);

  let initialLanguage = '';
  if (localStorage.getItem('language')) {
    initialLanguage = localStorage.getItem('language');
  } else if (window.navigator.language) {
    initialLanguage = window.navigator.language;
  } else {
    initialLanguage = 'en-US';
  }

  const [language, setLanguage] = useState(initialLanguage == 'en-US' ? en : ro);

  return (
    <Router>
      <LightModeContext.Provider value={{ lightMode, setLightMode }}>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <Header />
          <div className="page-content">
            <Routes>
              {/* Homepage  */}
              <Route path="/" element={<Home isLightMode={lightMode} />} />

              {/* Servicii */}
              <Route path="/services" element={<Services isLightMode={lightMode} />} />
              <Route path="/servicii" element={<Services isLightMode={lightMode} />} />

              {/* Portofoliu */}
              <Route path="/work" element={<Work isLightMode={lightMode} />} />
              <Route path="/portofoliu" element={<Work isLightMode={lightMode} />} />

              {/* Cariera */}
              <Route path="/career" element={<Career />} />
              <Route path="/cariera" element={<Career />} />

              {/* AppDevDescription */}
              <Route path="/career/app-dev-description" element={<AppDevDescription />} />
              <Route path="/cariera/app-dev-description" element={<AppDevDescription />} />

              {/* JobApply */}
              <Route path="/career/job-apply" element={<JobApply />} />
              <Route path="/cariera/aplicare-job" element={<JobApply />} />

              {/* Contact */}
              <Route path="/contact" element={<Contact />} />

              {/* Blog */}
              <Route path="/blog" element={<Blog />} />

              {/* Article */}
              <Route path="/blog/:id" element={<ArticlePage />} />

              {/* Terms and Conditions */}
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />

              {/* Privacy Policy */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />

              {/* Login */}
              <Route path="/login" element={<Login />} />

              {/* Welcome */}
              <Route path="/welcome" element={<Welcome />} />

              {/* Database */}
              <Route path="/database" element={<Database />} isLightMode={lightMode} />

              <Route path="/apps/:slug" element={<DescribeApp />} />

              {/* 404 error */}
              <Route path="/*" element={<Error404 />} />
            </Routes>
            <CookieConsent buttonText="Got it!">
              We use cookies to improve your experience on our website.{' '}
              <Link to="/privacy-policy" style={{ textDecoration: 'underline' }}>
                Learn more
              </Link>
            </CookieConsent>
          </div>
          <Footer />
        </LanguageContext.Provider>
      </LightModeContext.Provider>
    </Router>
  );
}

export default App;

import { useContext, useState, useEffect } from "react";
import { LightModeContext } from "../state/lightModeContext";
import { LanguageContext } from "../state/languageContext";
import { Link } from "react-router-dom";

import ro from '../translations/ro.js'
import en from '../translations/en.js'

const Header = () => {
  const [nav_active, toggleNav] = useState(false);
  const [is_sticky, toggleSticky] = useState(false);
  const [scroll, setScroll] = useState(0);

  // Light Mode context
  const {lightMode, setLightMode} = useContext(LightModeContext);
  
  // Language object
  const {language, setLanguage} = useContext(LanguageContext);

  window.onscroll = function () {
    // Logo sticky
    toggleSticky(window.scrollY > 0)

    // Function for progress bar on top
    var scrollTop = window.scrollY;
    var scrollBottom = (document.documentElement["scrollHeight"] || document.body["scrollHeight"]) -  document.documentElement.clientHeight;
    var scrollPercent = scrollTop / scrollBottom * 100 + "%";

    setScroll(scrollPercent);
  };
    // TODO: pe safari
  const toggleLightMode = () => {
    setLightMode(!lightMode); 
    document.body.classList.toggle('white-mode-site');
    localStorage.setItem('lightMode', lightMode==false ? 'enabled' : 'disabled');
  }

  const toggleLanguage = () => {
    setLanguage(language.translation_type == 'en' ? ro : en);
    localStorage.setItem('language', language.translation_type == 'en' ? 'ro-RO' : 'en-US');

    window.location.reload()
  }

  return (
    <>
      <div id="_progress" className="_progress" style={{ '--scroll': scroll }}></div>

      <header className="page-header">
        <nav>
          <div className="logo">
            <Link to="/"> <img src="/imagini/WOLF head300x300white.png" alt="lup" className={`imgLogo ${is_sticky ? 'sticky' : ''}`} /></Link>
          </div>

          <ul className={`nav-links ${nav_active ? 'nav-active' : ''}`}>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="/">{language.header.homepage}</Link></li>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="servicii">{language.header.services}</Link></li>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="portofoliu">{language.header.work}</Link></li>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="cariera">{language.header.career}</Link></li>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="blog">{language.header.blog}</Link></li>
            <li><Link onClick={() => toggleNav(false)} className="link-nav" to="contact">{language.header.contact}</Link></li>
            
            <div className="dropdown">
              <button className="dropbtn">{ language.translation_type } 
                <i style={{ marginLeft: '5px' }} className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <a style={{ cursor: 'pointer', color: '#000' }} onClick={() => toggleLanguage()}>{ language.translation_type == 'en' ? 'ro' : 'en' }</a>
              </div>
            </div>
          </ul>

          <div onClick={() => toggleNav(!nav_active)} className={`burger ${nav_active ? 'toggle' : ''}`}>
            <div className="line1 color-line"></div>
            <div className="line3 color-line"></div>
          </div>
        </nav>
      </header>

      <div className="switch-button">
        <p id="text-mode">Light<br />Dark</p>
        <input id="checkbox" type="checkbox" className="checkbox" defaultChecked={lightMode} />
        <label style={{ marginTop: '60px' }} htmlFor="checkbox" className="switch white-mode-toggle" onClick={() => toggleLightMode()}>
          <span className="switch__circle">
            <span className="switch__circle-inner"></span>
          </span>
        </label>
      </div>
    </>
  );
};

export default Header;

import Project from './WebWork/Project';
import { LanguageContext } from '../../state/languageContext';
import { useContext } from 'react';

const WebApps = () => {
  const { language } = useContext(LanguageContext);
  const projects = [
    {
      title: 'Lysium',
      src: 'lysium.png',
      desc_ro: 'Website de prezentare pentru companie de blockchain. Include implementarea design-ului cu sectiuni animate. ',
      desc_en: ' Presentation website developed for blockchain company. The work we did included the implementation of their custom design with animated sections.',
    },
    {
      title: 'Aurus',
      src: 'aurus.png',
      desc_ro: 'Website de prezentare pentru companie digitala. Include design customizat cu animatii, cat si pagini dedicate si integrate.',
      desc_en: 'Presentation website developed for digital company. We worked on the customized design with animations as well as on doing the integrated customized single-pages.',
    },
    {
      title: 'WMS Chauffeursdiensten',
      src: 'wmschauffeursdiensten.nl.png',
      desc_ro: 'Website de prezentare pentru serviciu olandez de rezervare de soferi. Include rezervarea directa prin intermediul site-ului web utilizand formulare customizate.',
      desc_en: 'Presentation website developed for dutch driver services business. Includes direct booking through the website using customized forms.',
    },
    {
      title: 'Buenasado',
      src: 'buenasado.ro.png',
      desc_ro: 'Site de prezentare creat pentru un restaurant romanesc. Include meniu integrat si customizat cu toate preparatele si bauturile restaurantului + modalitati de a rezerva o masa sau un loc la evenimentele organizate prin formular online.',
      desc_en: 'Presentation website developed for romanian restaurant. It includes customized menu with all the restaurant dishes and ways to book a table or reserve it for an event through website forms.',
    },
    {
      title: 'Lotus Chinese Therapy',
      src: 'lotuschinesetherapy.ro.png',
      desc_ro: 'Website de prezentare creat pentru o clinica terapeutica specializata in terapii chinezesti. Lucrarea include atat sectiunea de blog cat si pagini dedicate, customizate si integrate.',
      desc_en: 'Presentation website created for romanian terapeutic clinic specialised in chinese therapies. Our work includes blog area as well as integrated customized single-pages.',
    },
    {
      title: 'Mojar',
      src: 'mojar.ro.png',
      desc_ro: 'Versiune online de meniu dezvoltata sub forma de website pentru un serviciu romanesc de livrare de mancare. A fost creat ca un meniu integrat si receptiv, ce include toate preparatele si bauturile restaurantului.',
      desc_en: 'Online-menu website developed for romanian food delivery service. Was created as an online integrated & responsive menu with all the restaurant food & drinks options.',
    },
    {
      title: 'Museum',
      src: 'museum.ro.png',
      desc_ro: 'Site de prezentare creat pentru un restaurant romanesc. Include meniu integrat si customizat cu toate preparatele si bauturile restaurantului + modalitati de a rezerva o masa prin formular online.',
      desc_en: 'Presentation website developed for romanian restaurant. It includes customized menu with all the restaurant dishes & drinks and ways to book a table through website forms.',
    },
    {
      title: 'Playa9',
      src: 'playa9.ro.png',
      desc_ro: 'Website de prezentare dezvoltat pentru complex cu piscina. Include sectiuni customizate pentru fiecare buton din meniu, integrate in landing-page.',
      desc_en: 'Presentation landing-page developed for romanian pool complex. Includes custom section for every menu button integrated in the landing-page.',
    },
    {
      title: 'Byentourage E3',
      src: 'byentourage.jpg',
      desc_ro: 'Site de prezentare creat pentru un restaurant romanesc. Include meniu integrat si customizat cu toate preparatele si bauturile restaurantului.',
      desc_en: 'Presentation website created for romanian restaurant. It includes customized integrated menu with all the restaurant dishes & drinks.',
    },
    {
      title: 'Beautybase',
      src: 'beautybase.jpg',
      desc_ro: 'Magazine online dedicat produselor cosmetice, dezvoltat pentru un brand romanesc. Include modalitate integrata de a cumpara si plati prin intermediul website-ului.',
      desc_en: 'Online web-store for cosmetic products developed for romanian brand. Includes integrated shop options through the website.',
    },
  ];

  return (
    <section className="container-web-apps ">
      {projects.map((project, index) => (
        <Project key={index} title={project.title} src={project.src} desc={language.translation_type == 'ro' ? project.desc_ro : project.desc_en} />
      ))}
    </section>
  );
};

export default WebApps;

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const Categories = ({ categories_ids }) => {
  const [category, setCategory] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/categories/${categories_ids}`
      )
      .then((response) => {
        setCategory(response.data.name);
      });
  }, []);

  return <span dangerouslySetInnerHTML={{ __html: category}}></span>;
};

export default Categories;

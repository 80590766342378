import { Link } from "react-router-dom";
import { LanguageContext } from "../../../state/languageContext";
import { useContext } from "react";

const CaseStudies = ({ isLightMode }) => {
  const {language} = useContext(LanguageContext);
   return (
    <div className="cases"> 
      <h2 className="important-text" style={{ textAlign:"center" }} >{language.homepage.cases.title}</h2>
      <div className="weGotMore contact">
        <Link to="/portofoliu"><p className="text-arrow">{language.homepage.cases.look}</p></Link>
        <Link to="/portofoliu">
          <img  src={`imagini/${isLightMode ? 'baseline_arrow_right_alt_black_18dp' : 'baseline_arrow_right_alt_white_24dp'}.png`} />
        </Link>
      </div>
    </div>
  );
};

export default CaseStudies;

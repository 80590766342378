import '../css/privacyPolicy.css'

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <div id="privacy-policy">
      <div className="Cookie"> 
        <h1 className="cookie_header">Declaratia de confidentialitate a datelor</h1>
        <p className="cookie_p"><strong>A. General</strong><br />
          Pe baza conexiunii noastre de afaceri, primim date personale de la dumneavoastra. Aceste date sunt prelucrate - daca este necesar furnizarea serviciilor noastre sau pentru indeplinirea obligatiilor legale si de reglementare - impreuna cu datele cu caracter personal pe care din surse sau date accesibile publicului care sunt transmise in mod legitim de catre terti. Prelucram datele cu caracter personal in conformitate cu reglementarile privind protectia datelor. Temeiul juridic pentru prelucrare este esential pentru executarea unui contract sau pentru a efectua masuri precontractuale sau sa indeplineasca obligatiile legale la care suntem supusi. De asemenea, avem si caracter de neinlocuit cu 100.000 de lei. De exemplu, pentru protectia angajatilor si a pentru dezvoltarea in continuare a companiei noastre, a produselor si serviciilor pentru a recastiga clientii pentru a preveni combaterea fraudei si/sau urmarirea penala sau apararea impotriva revendicarilor legale [articolul 6 alineatul (1) litera (f) din RGPD].
          Daca ne-ati dat consimtamantul dumneavoastra, prelucrarea are loc in scopurile declarate [articolul 6 alineatul (1) litera (a) din RGPD]. Va puteti revoca consimtamantul in orice moment, fara a afecta legalitatea prelucrarii, efectuata pe baza consimtamantului pana la revocare. Revocarea poate fi transmisa prin e-mail <a href="mailto:office@wolfdigitalmedia.ro">office@wolfdigitalmedia.ro.</a>
        </p><br />
        <p className="cookie_p"><strong>B. Stocarea adreselor IP</strong><br />
          Cand vizitati site-ul nostru web, datele sunt stocate pe serverele noastre in scopuri de rezerva (cum ar fi adresa IP, timpul de apel si paginile vizitate). Procesam aceste date din motive de securitate a datelor si pentru a asigura stabilitatea si fiabilitatea sistemului nostru.
        </p> <br />
          <p className="cookie_p"><strong>C. Cookies</strong><br />
            Site-ul nostru web foloseste asa-numitele „cookie-uri” care sunt fisiere text care sunt stocate pe computer. Cookie-urile ne permit sa analizam utilizarea 
            generala a site-ului web prin utilizarea de persoane fizice. Puteti preveni instalarea cookie-urilor prin setarea software-ului browserului in consecinta. 
            Cu toate acestea, va rugam sa stiti ca, daca faceti acest lucru, este posibil sa nu puteti utiliza functionalitatea completa a acestui site web.
          </p ><br />
              <p className="cookie_p"><strong>D. Google Analytics</strong><br />
                Site-ul nostru utilizeaza Google Analytics, un serviciu de analiza web furnizat de Google Inc. (in continuare „Google”).
              Google Analytics utilizeaza asa-numitele „cookie-uri”, fisiere text care sunt stocate pe computerul dvs. si care permit o analiza a utilizarii generale a site-ului web 
              de catre persoane fizice. Informatiile despre utilizarea site-ului dvs. web, generate de cookie-uri, sunt de obicei transmise catre un server Google din SUA, unde sunt stocate.
                Am activat anonimizarea IP. Aceasta inseamna ca adresa dvs. IP va fi trunchiata de Google in cadrul membrilor Uniunii Europene si al altor state parti la Acordul privind
              Spatiul Economic European. In numele nostru, Google va folosi aceste informatii pentru a evalua utilizarea site-ului dvs. web, pentru a compila rapoarte despre activitatea
              site-ului si pentru a furniza alte servicii legate de activitatea site-ului si de utilizare a internetului prin intermediul operatorilor site-ului.
                Adresa IP furnizata de Google Analytics in cadrul Google Analytics nu va fi contopita cu alte date furnizate de Google. Puteti impiedica stocarea cookie-urilor prin setarea
              software-ului browserului web in consecinta. Subliniem, totusi, ca in acest caz este posibil sa nu puteti utiliza toate functiile site-ului nostru web. In plus, puteti impiedica
              colectarea de catre Google a datelor generate de cookie si legate de utilizarea site-ului dvs. web (inclusiv adresa dvs. IP) si procesarea acestor date de catre Google folosind 
              linkul de mai jos (http: // tools .google.com / dlpage / gaoptout? hl = DE) Descarcati si instalati pluginul de browser disponibil.
                Puteti impiedica colectia de Google Analytics facand clic pe linkul urmator. Un cookie de renuntare este setat pentru a preveni colectarea viitoare a datelor dvs. atunci 
              cand vizitati acest site: Google Analytics renuntare va rugam sa retineti ca trebuie sa faceti clic din nou pe acest link daca ati sters toate cookie-urile din browser. 
              Pentru mai multe informatii despre Termeni de utilizare si confidentialitate, va rugam sa vizitati 
              <a href="https://www.google.com/analytics/terms/us.html">www.google.com/analytics/terms/us.html</a>
              sau <a href="https://www.google.com/intl/en/policies/">https://www.google.com/intl/en/policies</a></p><br />
                <p className="cookie_p"><strong>E. Google AdWords</strong> <br />
                Folosim serviciul de publicitate online „Google AdWords” pe site-ul nostru web si, in acest context, urmarirea conversiilor (evaluarea vizitatorilor). 
              Urmarirea conversiilor Google este un serviciu de analiza furnizat de Google Inc. (1600 Amphitheater Parkway, Mountain View, CA 94043, SUA). Cand faceti clic pe un anunt difuzat
              de Google, un cookie de urmarire a conversiilor va fi plasat pe computerul personal. Aceste cookie-uri au o valabilitate limitata, nu contin date personale si, prin urmare, 
              nu sunt utilizate pentru identificarea personala. Daca vizitati anumite pagini de pe site-ul nostru web si cookie-ul nu a expirat, noi insine si Google putem recunoaste ca ati 
              facut clic pe anunt si ati fost redirectionat catre aceasta pagina. Fiecare client Google AdWords primeste un cookie diferit. Astfel, nu este posibil ca cookie-urile sa poata fi
              urmarite prin site-urile web ale agentilor de publicitate.
                  Informatiile obtinute cu ajutorul cookie-ului de conversie sunt utilizate pentru a crea statistici de conversie. Acest lucru ne arata numarul total de utilizatori care au 
              facut clic pe una dintre reclamele noastre si au fost redirectionate catre o pagina de eticheta de urmarire a conversiilor. Cu toate acestea, nu primim nicio informatie care 
              sa identifice personal utilizatorii. Prelucrarea se bazeaza pe art. 6 (1) lit. f GDPR din interesul legitim pentru publicitatea vizata si analiza impactului si eficientei acestei publicitati.
                  Aveti dreptul, din motive care decurg din situatia dvs. particulara, la date care va privesc, in baza art. 6 (1) f GDPR.
                  Puteti impiedica stocarea cookie-urilor prin selectarea setarilor tehnice adecvate ale software-ului dvs. de browser. Subliniem, totusi, ca in acest caz este posibil sa nu 
              puteti utiliza pe deplin toate functiile acestui site web. Nu veti fi inclus in statisticile de urmarire a conversiilor.
                  De asemenea, puteti renunta la publicitatea personalizata in Setarile anunturilor Google.
                  Pentru instructiuni, consultati <a href="https://support.google.com/ads/answer/2662922?hl=ro"> support.google.com/ads/answer/2662922?hl=ro. </a> 
                  In plus, puteti dezactiva utilizarea cookie-urilor terte parti accesand pagina de dezactivare a Network Advertising Initiative la <a href="https://www.networkadvertising.org/choices/"> 
                  https://www.networkadvertising.org / options / </a> si implementarea informatiilor de renuntare enumerate acolo.
                  Pentru mai multe informatii si politica de confidentialitate Google, vizitati: 
                  <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/.</a><br />
                  </p><br />
                  <p className="cookie_p"><strong>F. Social media plug-ins</strong><br />
                    Site-ul utilizeaza programe - asa-numitele plugin-uri - de pe Facebook si Twitter.
                    Acestea sunt operate de la:<br /><br />
                    •	Facebook Inc., 1601 S. California Avenue, Palo Alto, CA 94304, USA (Facebook)<br />
                    •	Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103f, United States (Twitter)<br /><br />
                    Plug-in-urile pot fi recunoscute prin sigla retelei sociale corespunzatoare sau prin adaugarea de „Like” sau „Share”. In general, retelele de socializare care utilizeaza
                plugin-urile sociale surprind adresa IP a fiecarui vizitator al unei pagini imediat cand viziteaza pagina si isi inregistreaza alte activitati pe Internet. Acest lucru se
                intampla chiar daca utilizatorul nu face clic pe unul dintre butoane. Daca vizitatorul este deja conectat intr-o retea sociala in acelasi timp, partajarea pe Facebook se face
                fara alta fereastra. Pe Twitter, apare o fereastra pop-up in care puteti inca sa editati textul de pe tweet.
                    Daca sunteti conectat in paralel cu retelele de socializare (de exemplu, Facebook) in timp ce vizitati site-ul nostru web, nu poate fi exclus faptul ca furnizorul va 
                atribui vizita in contul dvs. de Facebook. Daca utilizati functiile plug-in (de exemplu, faceti clic pe butonul „Like”, trimiteti un comentariu), aceste informatii vor fi, 
                de asemenea, transmise direct din browserul dvs. in reteaua de socializare si stocate acolo, daca este necesar. Scopul si sfera de colectare a datelor si prelucrarea si 
                utilizarea ulterioara a datelor de catre retele pot fi gasite in politica de confidentialitate:
                    <br /><br /><a href="https://www.facebook.com/about/privacy/">• https://www.facebook.com/about/privacy/</a><br />   
                    <a href="https://www.facebook.com/about/privacy/">• https://twitter.com/privacy?lang=de</a>
                    </p ><br />
                    <p className="cookie_p"><strong>G. Cookie-uri de la terti (Facebook)</strong><br />
                      Cu acordul dumneavoastra, utilizam „pixelul Facebook” al Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, SUA („Facebook”) pe site-ul nostru web. Cu ajutorul 
                sau, putem urmari actiunile utilizatorilor dupa ce au vazut sau au dat clic pe un anunt Facebook. Acest lucru ne permite sa urmarim eficienta publicitatii Facebook in 
                scopuri statistice si de cercetare de piata. Datele colectate in acest mod sunt anonime pentru noi. Aceasta inseamna ca nu vedem datele personale ale utilizatorilor 
                individuali. Cu toate acestea, aceste date sunt stocate si procesate de Facebook, despre care va informam in functie de cunostintele noastre. Facebook poate conecta aceste
                date cu conturi Facebook individuale si le poate folosi in scopuri proprii de publicitate, conform politicii de confidentialitate Facebook 
                <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/.</a>
                Puteti activa sau dezactiva pe Facebook sau afiliati Facebook si afiliatii acestora in contul dvs. Facebook:  
                <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads.</a> 
                Acesta poate fi, de asemenea, stocat in aceste scopuri, un cookie pe computer.
                      Acest consimtamant poate fi declarat numai de utilizatorii mai mari de 13 ani. Daca sunteti mai tineri, va rugam sa consultati tutorele pentru sfaturi.
                      Va rugam sa faceti clic aici daca doriti sa va retrageti consimtamantul: <a href="http://www.youronlinechoices.com/uk/your-ad-choices">
                          http://www.youronlinechoices.com/uk/your-ad-choices</a>
                      </p> <br />

                      <p className="cookie_p"><strong>H. Facebook Remarketing / Retargeting</strong><br />
                      Paginile noastre includ etichete de remarketing din reteaua de socializare Facebook, 1601 South California Ave., Palo Alto, CA 94304, SUA.
                    Cand vizitati paginile noastre, etichetele de remarketing fac o legatura directa intre browserul dvs. si serverul Facebook. Facebook primeste informatiile 
                    pe care le-ati vizitat pe site-ul nostru cu adresa dvs. IP. Drept urmare, Facebook poate atribui vizita la paginile noastre contului de utilizator. 
                    Putem folosi aceste informatii pentru afisarea anunturilor Facebook. Subliniem ca noi - in calitate de furnizor de pagini - nu suntem constienti de continutul 
                    datelor transmise si de utilizarea acestora de catre Facebook. 
                    Mai multe informatii pot fi gasite in Politica de confidentialitate Facebook la <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/.</a>
                    Alte setari si neconcordante cu privire la utilizarea datelor in scopuri promotionale sunt posibile in setarile profilului Facebook: 
                    <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads.</a> Setarile sunt independente de platforma, adica sunt adoptate pentru 
                    toate dispozitivele, cum ar fi computerele desktop sau dispozitivele mobile. Daca nu doriti sa colectati date prin intermediul publicului personalizat, 
                    puteti dezactiva acolo si Publicul personalizat.
                    </p> <br />

                    <p className="cookie_p"><strong>I. Link-uri catre alte site-uri web sau aplicatii pentru a descarca</strong><br />
                      Deoarece site-ul nostru web contine link-uri catre alte site-uri de internet (cum ar fi referinte sursa) sau catre site-uri web terte (cum ar fi SlideShare) 
                      sau ofera aplicatii pentru descarcare, aceasta politica de confidentialitate nu se aplica. Va rugam sa va informati pe alte site-uri web sau aplicatii cu privire 
                      la reglementarile de protectie a datelor care se aplica acolo.
                    </p> <br />

                    <p className="cookie_p"><strong>J. Aplicatii</strong><br />
                    In scopul procesarii cererilor si al procesului de selectie, Wolf Digital Media prelucreaza datele transmise in mod voluntar de catre solicitant. 
                Acest lucru se poate face prin intermediul site-ului web cu formularul de solicitare disponibil sau prin e-mail. Pentru aplicarea online, colectam urmatoarele date:
                numele, e-mailul si daca este furnizat de buna voie numarul de telefon si nationalitatea solicitantului. Baza legala pentru prelucrare este consimtamantul 
                (articolul 6 alineatul (1) litera (a) GDPR). In contextul fiecarei notificari vacante, se subliniaza faptul ca candidatul este de acord cu depunerea cererii de procesare 
                in scopurile de mai sus. In consecinta, consimtamantul este dat prin depunerea cererii.
                      Consimtamantul poate fi revocat in orice moment, fara a afecta legalitatea prelucrarii efectuate pe baza consimtamantului pana la revocare.
                        Revocarea poate fi facuta prin e-mail la <a href="mailto:office(@)wolfdigitalmedia.ro">office@wolfdigitalmedia.ro</a>
                    </p> <br />

                    <p className="cookie_p"><strong>K. Beneficiar al datelor / procesorului personal</strong><br />
                      Wolf se asigura ca informatiile personale sunt prelucrate numai de catre posturile interne sau angajatii care au nevoie de acestea pentru a-si indeplini sarcinile si obligatii.
                      Un transfer de date catre terti se face numai in masura necesara. Destinatarii datelor cu caracter personal pot include furnizori de servicii IT, furnizori de hosting si
                      servicii cloud pentru stocarea si procesarea datelor, gestionarea proiectului si corespondenta companii de comunicare, baze de date de licente de imagine sau muzica
                      pentru achizitionarea de fisiere relevante, companii de imprimare pentru tiparire personalizate documente, companii de marketing pentru efectuarea serviciilor 
                      de social media, banci, consultanti fiscali si avocati pentru executarea contractelor. Obligam contractual toti procesatorii sa proceseze datele cu caracter
                      personal numai in cadrul furnizarii serviciilor respective si folosind masuri tehnice si organizatorice adecvate.
                      Prelucrarea datelor cu caracter personal nu poate exclude intotdeauna prelucrarea datelor intr-o tara terta. Cu toate acestea, ne asiguram ca
                      datele sunt supuse unui nivel adecvat de protectie a datelor si ca dispozitiile articolului 44ff GDPR sunt respectate.            
                    </p> <br />

                    <p className="cookie_p"><strong>L. Securitatea datelor</strong><br />
                      Site-ul nostru web si alte sisteme sunt protejate prin masuri tehnice si organizatorice impotriva pierderii, distrugerii, accesului, modificarii
                      si diseminarii datelor dvs. de catre persoane neautorizate. In pofida eforturilor noastre de a mentine un standard ridicat de diligenta cuvenita,
                      nu poate fi exclusa faptul ca informatiile pe care ni le furnizati pe internet vor fi vizualizate si utilizate de altii. Va rugam sa retineti ca nu 
                      ne asumam nicio responsabilitate pentru divulgarea informatiilor din cauza erorilor care nu sunt cauzate de noi in transmiterea datelor si / sau accesul 
                      neautorizat de catre terti (de exemplu, atacul hackerilor).
                    </p> <br />

                    <p className="cookie_p"><strong>M. Timp de depozitare</strong><br />
                      Stocam date cu caracter personal pe toata durata unei relatii de afaceri sau cu un consimtamant dat. In plus, stocam date cu caracter 
                      personal atata timp cat exista obligatii legale de stocare si documentare sau exista perioade de prescriptie legala. In plus, stocam datele cu 
                      caracter personal atat timp cat sunt necesare in legatura cu apararea sau executarea oricaror pretentii legale si de raspundere.
                    </p> <br />
                    
                    <p className="cookie_p"><strong>N. Drepturile dvs. in legatura cu datele personale</strong><br />
                      Puteti, printre altele, sa aveti dreptul (sub rezerva legislatiei in vigoare), (i) sa verifice daca si ce informatii personale am stocat despre dvs. 
                      si sa primim copii ale acestor date, (ii) sa corecteze, sa completeze sau sa stearga personalul dvs. informatii, (iii) pentru a ne solicita sa restrictionam
                      prelucrarea datelor dvs. cu caracter personal si (iv) in anumite circumstante, sa obiectam la prelucrarea datelor dvs. personale sau sa consimtim in prealabil
                      la prelucrarea datelor dvs. personale (v) la solicita portabilitatea datelor (vi) pentru a cunoaste identitatea oricarui tert caruia ii sunt transmise 
                      informatiile dvs. personale si (vii) sa depuna o plangere la autoritatea relevanta.
                    </p> <br />

                    <p className="cookie_p"><strong>O. Legal remedies/Remedii legale</strong><br />
                      Daca doriti sa depuneti o cerere, deoarece considerati ca a fost incalcat dreptul dvs. de informatie, incalcarea drepturilor de confidentialitate, 
                      rectificarea sau anularea, Autoritatea pentru Protectia Datelor este responsabila:
                    </p> <br />

                    <p className="cookie_p space-footer"><strong>P. Datele noastre de contact</strong><br />
                      Daca aveti intrebari sau nelamuriri cu privire la prelucrarea datelor dvs. personale, va rugam sa ne adresati-ne:
                      Wolf Digital Media, Strada Scoala Floreasca nr.31, Bucuresti, <a href="mailto:office(@)wolfdigitalmedia.ro">
                        office@wolfdigitalmedia.ro</a>, <a href="tel:0040726585045">0040.726.585.045</a>
                    </p> <br />

      </div>

    </div>
  );
};

export default PrivacyPolicy;

import '../../css/dashboard.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate();

  // redirect daca e logat catre welcome page
  useEffect(() => {
    if(window.localStorage.getItem('loggedIn') && window.localStorage.getItem('username')){
      navigate('/welcome', { replace: true });
    }
  })

  const [usernameErr, toggleUsernameErr] = useState('');
  const [passwordErr, togglePasswordErr] = useState('');

  const validateForm = (e) => {
    e.preventDefault();
    const form = document.forms['login-form']
    const username = form['username'].value;
    const password = form['password'].value;

    let errors = false;
    
    if(!username){
      toggleUsernameErr('Username-ul este obligatoriu');
      errors = true;
    }
    else{
      toggleUsernameErr('');
    }

    if(!password){
      togglePasswordErr('Parola este obligatorie');
      errors = true;
    }
    else{
      togglePasswordErr('');
    }

    if(!errors){
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/login`,
        data: {
          username,
          password
        }
      })
      .then(response => {
        toggleUsernameErr(response.data.userNotFound)
        if(response.data.hasOwnProperty('passwordValid'))
          togglePasswordErr(response.data.passwordValid ? '' : 'Parola este incorecta.')

        console.log(response.data);
        if(response.data.shouldRedirect){
          localStorage.setItem('loggedIn', true);
          localStorage.setItem('id', response.data.id);
          localStorage.setItem('username', response.data.username);
          navigate('/welcome', { replace: true });
        }
      })
    }
  }

  return (
    <div id="login-page">
      <div className="wrapper">
        <h2>Login</h2>
        <p>Introdu datele de logare.</p>
        <form onSubmit={validateForm} name="login-form">
          <div className={`form-group ${usernameErr ? 'has-error' : ''}`}>
            <label>Nume de utilizator</label>
            <input type="text" name="username" className="form-control" />
            <span className="help-block">{ usernameErr }</span>
          </div>    
          <div className={`form-group ${passwordErr ? 'has-error' : ''}`}>
            <label>Parola</label>
            <input type="password" name="password" className="form-control" />
            <span className="help-block">{ passwordErr }</span>
          </div>
          <div className="form-group">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>    
    </div>
  )
}

export default Login
import Client from "./OurClients/Client"
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";


const Clients = () => {
  const clients = [ 'e3.png', 'e3_cv.png', 'homealarms.png', 'cosmocris.png', 'iscointellis.png', 'khoong.png', 'roel.png', 'setup.png', 'b4.png', 'bigballroom.png']
  const {language} = useContext(LanguageContext);
  return (
    <section id="section-clients">
      <h3 className="important-text">{language.homepage.clients}</h3>
      <div className="block_bg"></div>
      <div className="container-clients">
        { 
          clients.map((client, index) => <Client key={index} src={client} />)
        }
      </div>
    </section>
  );
};

export default Clients;

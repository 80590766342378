import '../css/career.css'
import '../css/generale.css'
import { Link } from 'react-router-dom';
import { LanguageContext } from "../state/languageContext";
import { useContext } from "react";

const Career = () => {
  
  // Language object
  const {language} = useContext(LanguageContext);

  return (
    <div id="career-page">

      <section className="services">
        <h1 className="Important-text" id="services1">{language.career.hero.title}</h1>
        <p className=" aliniere2" id="aliniere2">
        {language.career.hero.description.line1}<br className="brCareers"></br>{language.career.hero.description.line2}<br className="brCareers"></br>{language.career.hero.description.line3}<br></br>
        </p>
        <p className="careers-text">{language.career.hero.open_position}</p>
      </section>

      <section className="section-goTo-jobDescription">
        <Link to="/career/app-dev-description" className="careers_a">
          <div className="block_section">
            <div className="btnContainer">
              <span className="span_carrers"><img src="imagini/baseline_arrow_right_alt_black_18dp.png" alt="" className="careers_arrow"></img></span>
              <span className="span_carrers">{language.career.goToJobDescription.apply}</span>
              <span className="block_text">{language.career.goToJobDescription.job_position}</span>
            </div>
          </div>
        </Link>
      </section>

    </div>
  );
};

export default Career;



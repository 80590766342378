const Map = () => {
  return (
    <div className="harta-locatie">
      <iframe id="harta"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.770411114888!2d26.097032415795947!3d44.45837880800878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f8a893524ea7%3A0xf2b92fc49a04edce!2sStrada%20%C8%98coala%20Floreasca%2038%2C%20Bucure%C8%99ti!5e0!3m2!1sro!2sro!4v1576240900787!5m2!1sro!2sro"
        width="100%" height="600" frameBorder="0" allowFullScreen="">
      </iframe>
    </div>
  );
};

export default Map;

import '../../css/appDevDescription.css'
import '../../css/generale.css'
import { Link } from 'react-router-dom';
import { LanguageContext } from "../../state/languageContext";
import { useContext} from "react";

const AppDevDescription = () => {
    // Language object
  const {language} = useContext(LanguageContext);

  return (
    <div id="app-dev-description-page">

        <section className="section-job-description">
            <h2 className="head-text-section-job-description">{language.jobDevDescription.hero.title}</h2>
            <div className="col_2">
                <p className="ios-text">{language.jobDevDescription.hero.description}</p>
            </div>
        </section>

        <div className="section-skills-required">
            <h3 className="section-skills-required-class">{language.jobDevDescription.must_have.title}</h3>
            <div className="ios-dev-requiest">
                <ul className="ul-ios">
                    <li>{language.jobDevDescription.must_have.skills.line1}</li>
                    <li>{language.jobDevDescription.must_have.skills.line2}</li>
                    <li>{language.jobDevDescription.must_have.skills.line3}</li>
                </ul>
            </div>
        </div>
                    
        <div className="section-skills-required">
            <h3 className="section-skills-required-class">{language.jobDevDescription.nice_to_have.title}</h3>
            <div className="ios-dev-requiest">
                <ul className="ul-ios">
                    <li>{language.jobDevDescription.nice_to_have.skills.line1}</li>
                    <li>{language.jobDevDescription.nice_to_have.skills.line2}</li>
                    <li>{language.jobDevDescription.nice_to_have.skills.line3}</li>
                </ul>
            </div>
        </div>
                    
        <div className="section-goTo-jobApply"> 
            <Link to="/career/job-apply" className="section-goTo-jobApply-button">
                <div className="button-careers">
                    <p className="button-header">{language.jobDevDescription.goToJobApply.job_position}</p>   
                    <p className="button-text">{language.jobDevDescription.goToJobApply.job_apply}
                    <img id="arrow" alt="" className="button-text-img" src="../../imagini/baseline_arrow_right_alt_white_24dp.png"></img>
                    </p>
                </div>
            </Link>
        </div>

    </div>
  );
};

export default AppDevDescription;



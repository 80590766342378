import axios from "axios"
import { useState } from "react";
import { useEffect } from "react"

const Author = ({ author_id }) => {
  const [author, setAuthor] = useState('');

  useEffect(() => {
    axios.get(`https://dashboard.wolfdigitalmedia.ro/wp-json/wp/v2/users/${author_id}`)
    .then(response => {
      setAuthor(response.data.name)
    })
  }, []);
  
  return (
    <span>{author}</span>
  )
}

export default Author
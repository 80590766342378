const ro = {
  // translation type
  "translation_type": "ro",

  // header
  "header": {
    "homepage": "Despre noi",
    "services": "Servicii",
    "work": "Portofoliu",
    "career": "Cariera",
    "blog": "Blog",
    "contact": "Contact"
  },

  // homepage
  "homepage": {
    "hero": {
      "description": {
        "line1": "Dezvoltam aplicatii mobile",
        "typeit": {
          "string1": "inteligente",
          "string2": "adaptive"
        },
        "line3": "si web!",
      },
      "contact_us": {
        "line1": "IA LEGATURA CU NOI",
        "line2": "Contactati-ne pentru potentiale proiecte"
      }
    },
    "mobile_apps": {
      "title": "Aplicatii Mobile",
      "description":{
        "line1" : "Folosind",
        "line2": "construim aplicatii native"
      }
    },
    "our_stacks": {
      "title": "Tehnologiile noastre"
    },
    "cases": {
      "title": "Avem mai multe",
      "look": "Arunca o privire la portofoliul nostru"
    },
    "companies": {
      "title": "Magazine online si site-uri de prezentare",
      "description": {
        "line1": "Explorarea intersectiilor",
        "line2": "dintre",
        "typeit": {
          "string1": "design",
          "string2": "tehnologie digitala",
        },
        "line3": "si continut pentru brand-uri"
      }
    },
    "web_work": {
      "title": "PORTOFOLIU WEB",
      "arrow": {
        "show": "Vezi",
        "more": "mai mult",
        "less": "mai putin"
      }
    },
    "clients": "Clientii nostrii",
    "footer_home": {
      "contact_us": {
        "line1": "IA LEGATURA CU NOI",
        "line2": "Contactati-ne pentru potentiale proiecte"
      },
      "join": {
        "line1": "Vrei sa lucrezi la wolf?",
        "line2": "Momentan avem un singur post liber"
      }
    }
  },

  // services
  "services":{
    "hero": {
      "title": "Servicii",
      "description": {
        "line1": "Ajutam",
        "typeit": {
          "string1": "brandurile",
          "string2": "companiile",
        },
        "line3": "sa iasa in evidenta",
        "line4": "in era digitala.",
      },
    },
    "our_services": "Departamentele noastre",
    "contact_us": {
      "line1": "IA LEGATURA CU NOI",
      "line2": "Contactati-ne pentru potentiale proiecte"
    }
  },

  // work
  "work":{
    "hero": {
      "title": "Portofoliu",
      "description":{
        "line1": "Cateva din proiectele",
        "line2": "noastre pentru",
        "line3": "clienti multumiti",
      }
    },
    "our_apps": "Aplicatiile noastre",
    "our_work": "O parte din portofoliu",
    "contact_us": {
      "line1": "IA LEGATURA CU NOI",
      "line2": "Contactati-ne pentru potentiale proiecte"
    }
  },

  //career
  "career": {
    "hero":{
      "title" : "Cariera",
      "description": {
        "line1": "Ai ajuns unde trebuie",
        "line2": "Esti pe calea",
        "line3": "spre viitorul digital",
      },
      "open-position": "Momentan avem o singura pozitie disponibila."
    },
    "goToJobDescription": {
      "apply": "Aplica",
      "job_position": "Junior Flutter Developer"
    }
  },

  //job-description
  "jobDevDescription": {
    "hero": {
      "title": "Viata ca Junior Flutter Developer",
      "description": "In calitate de Junior Flutter Developer, vei lucra in cadrul unei echipe multidisciplinare care dezvolta aplicatii mobile cross-platform pentru clientii nostri. Vei fi implicat in intregul proces pentru dezvoltarea proiectelor, de la stadiul de idei pana la livrarea finala."
    },
    "must_have": {
      "title": "Trebuie sa ai:",
      "skills": {
        "line1": "Experienta de lucru cu Flutter(trebuie demonstrata prin proiecte personale);",
        "line2": "Experienta de lucru cu Git;",
        "line3": "Dorinta de a invata."
      }
    },
    "nice_to_have": {
      "title": "Este bine sa ai:",
      "skills": {
        "line1": "Experienta de lucru cu Firebase, preferabil alaturi de Flutter;",
        "line2": "Experienta cu instrumentele de gestionare Flutter precum MobX;",
        "line3": "Cunostinte de baza despre RESTful APIs."
      }
    },
    "goToJobApply": {
      "job_position": "Junior Flutter Developer",
      "job_apply": "Aplica pentru job"
    }
  },

  //job-apply
  "job_apply": {
    "title": "Aplica pentru job!",
    "form_apply":{
      "name": "Nume",
      "error_name": "Numele este obligatoriu.",
      "error_valid_name": "Numele nu este valid.",
      "email": "Email",
      "error_email": "Emailul este obligatoriu.",
      "error_valid_email": "Emailul nu este valid.",
      "phone": "Telefon",
      "error_phone": "Numarul de telefon este obligatoriu.",
      "error_valid_phone": "Numarul de telefon nu este valid.",
      "message": "Mesaj",
      "error_message": "Mesajul este obligatoriu.",
      "files_allowed": "Doar fisere JPG, JPEG, PNG & PDF sunt permise:",
      "error_recaptcha": "Ai uitat sa bifezi.",
      "submit_btn": "Trimite"
    }
  },

  //blog
  "blog": {
    "hero": {
      "title": "Blog",
      "description": "Acum ai ajuns in sectiunea de blog. Stai aproape pentru un nou articol saptamanal."
    },
    "post_on": "Postata pe",
    "by":"de",
    "subscribe_form": {
      "title": "ABONEAZA-TE",
      "description": "Pentru a fi la curent cu noutatile noastre saptamanale, aboneaza-te la newsletter",
      "email": "Email-ul tau",
      "submit_btn" : "ABONEAZA-TE"
    },
    "popular_post":"POSTARI POPULARE",
    "categories":"CATEGORII"
  },

  //article
  "article":{
    "post_on": "on",
    "by":"Postata de",
    "comments":"COMENTARII",
    "leave_comment":{
      "title":"LASA UN COMENTARIU",
      "name":"Nume...",
      "email": "Email...",
      "message": "Mesajul tau...",
      "send_comment": "TRIMITE COMENTARIUL"
    },
    "previous":"Articolul anterior",
    "next":"Articolul urmator"
  },

  //contact
  "contact": {
    "title": "TE ASTEPTAM LA O CAFEA",
    "visit_us": {
      "title": "Viziteaza-ne",
      "address": "Strada Scoala Floreasca Nr. 38"
    },
    "contact_form": {
      "title": "Trimite-ne un mesaj",
      "name": "Nume",
      "email": "Email",
      "subject": "Subiect",
      "message": "Mesaj",
      "submit_btn": "Trimite"
    }
  },

  // footer
  "footer": {
    "credits": "by Wolfdigitalmedia. Toate drepturile rezervate.",
    "privacy_policy": "Politica de confidentialitate"
  }
}

export default ro;
import TypeIt from "typeit-react";
import { LanguageContext } from "../../state/languageContext";
import { useContext } from "react";

const Hero = () => {
  const {language} = useContext(LanguageContext);
  const typeit_options = { 
    strings: [`${language.services.hero.description.typeit.string1}`,`${language.services.hero.description.typeit.string2}`],
    speed: 200,
    startDelay: 500,
    deleteSpeed: 100,
    nextStringDelay: 2000,
    breakLines: false,
    lifeLike: true,
    waitUntilVisible: true,
    loop: true,
    loopDelay: 1500
  }
  return (
    <section className="first-line">
      <h1 className="service-page-title text-color-important">{language.services.hero.title}</h1>
      <p className="description">
      {language.services.hero.description.line1} &nbsp; 
        <TypeIt 
        className="has-text-link typewriter3 type-services"
        options={ typeit_options }/>
        <br />
        {language.services.hero.description.line3}<br />
        {language.services.hero.description.line4}<br />
      </p>
    </section>
  );
};

export default Hero;
